export const formSubmitHandler = (values, action) => ({
  id_info_peluang: action === "UPDATE" ? values?.id_info_peluang : undefined,
  tgl_info_peluang: values?.tgl_info_peluang,
  no_info_peluang: values?.no_info_peluang,
  keterangan: values?.keterangan,
  status_info_peluang:
    action === "STATUS"
      ? parseInt(parseInt(values?.status_info_peluang) + 1)
      : values?.status_info_peluang,
  tgl_status_info_peluang: values?.tgl_status_info_peluang,
  id_customer: values?.id_customer,
  id_proyek: values?.id_proyek,
  id_nama_marketing: values?.id_nama_marketing,
  baseline:
    action === "STATUS" || action === "UPDATE" ? values?.baseline : undefined,
});
