import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { AnalisaBarangJadiMarketingApi } from "api";

import { ModalFilter } from "./Comp";

export const AnalisaBarangJadiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const state = location?.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    jenis_barang: state?.dataFilter?.jenis_barang,
    status_approval: state?.dataFilter?.status_approval,
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalStatusInfoPeluang, setModalStatusInfoPeluang] = useState({
    data: {},
    show: false,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    AnalisaBarangJadiMarketingApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      jenis_barang: dataFilter?.jenis_barang,
      status_approval: dataFilter?.status_approval,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.registerAlertConfig) {
        setAlertConfig({
          show: locationState.registerAlertConfig.show,
          text: locationState.registerAlertConfig.text,
          variant: locationState.registerAlertConfig.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Analisa Barang Jadi");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter?.active,
    dataFilter?.jenis_barang,
    dataFilter?.status_approval,
  ]);

  const PageContent = () => {
    const getStatusApprovalLabel = (status_approval) => {
      switch (status_approval) {
        case "APP":
          return {
            variant: "outline-success",
            label: "APPROVED",
          };
        case "REJ":
          return {
            variant: "outline-danger",
            label: "REJECT",
          };
        case "REV":
          return {
            variant: "outline-warning",
            label: "REVISI",
          };
        case "VER":
          return {
            variant: "outline-success",
            label: "VERIFIED",
          };
        default:
          return {
            variant: "outline-secondary",
            label: "PENDING",
          };
      }
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Analisa Barang Jadi</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Barang Jadi</ThFixed>
              <Th>Nama Barang Jadi</Th>
              <Th>Varian</Th>
              <Th>Kelompok Barang</Th>
              <Th>Jenis Barang</Th>
              <ThFixed>Status Approval</ThFixed>
              <ThFixed>Update Terakhir</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    <ReadButton
                      onClick={() =>
                        history.push(
                          `/master/analisa-barang-jadi/detail/${val.id_analisa_barang_jadi}`,
                          {
                            dataFilter,
                          }
                        )
                      }
                    />
                  </div>
                </TdFixed>
                <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                <TdFixed className="text-nowrap">
                  {val.nama_item ?? "-"}
                </TdFixed>
                <Td>{val.nama_varian ?? "-"}</Td>
                <Td>{val.nama_kelompok ?? "-"}</Td>
                <Td>{val.nama_jenis ?? "-"}</Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      getStatusApprovalLabel(val.status_approval).variant
                    }
                  >
                    {getStatusApprovalLabel(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
                <TdFixed>
                  {DateConvert(new Date(val.tgl_analisa_barang_jadi))?.custom ??
                    "-"}
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};
