import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { InfoPeluangApi } from "api";

import {
  ModalStatusInfoPeluang,
  StatusInfoPeluangButton,
  ModalFilter,
} from "./Comp";

const InfoPeluang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const state = location?.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    tgl_info_peluang_mulai: state?.dataFilter?.tgl_info_peluang_mulai,
    tgl_info_peluang_selesai: state?.dataFilter?.tgl_info_peluang_selesai,
    nama_marketing: state?.dataFilter?.nama_marketing,
    customer: state?.dataFilter?.customer,
    proyek: state?.dataFilter?.proyek,
    status_info_peluang: state?.dataFilter?.status_info_peluang,
    status_approval: state?.dataFilter?.status_approval,
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalStatusInfoPeluang, setModalStatusInfoPeluang] = useState({
    data: {},
    show: false,
  });

  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    InfoPeluangApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tgl_info_peluang_mulai: dataFilter?.tgl_info_peluang_mulai,
      tgl_info_peluang_selesai: dataFilter?.tgl_info_peluang_selesai,
      nama_marketing: dataFilter?.nama_marketing,
      customer: dataFilter?.customer,
      proyek: dataFilter?.proyek,
      status_info_peluang: dataFilter?.status_info_peluang,
      status_approval: dataFilter?.status_approval,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.registerAlertConfig) {
        setAlertConfig({
          show: locationState.registerAlertConfig.show,
          text: locationState.registerAlertConfig.text,
          variant: locationState.registerAlertConfig.variant,
        });
      }
    }
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        });
        setPaginationConfig({
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("Info Peluang");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter?.active,
    dataFilter?.tgl_info_peluang_mulai,
    dataFilter?.tgl_info_peluang_selesai,
    dataFilter?.nama_marketing,
    dataFilter?.customer,
    dataFilter?.proyek,
    dataFilter?.status_info_peluang,
    dataFilter?.status_approval,
  ]);

  const PageContent = () => {
    const getStatusApprovalLabel = (status_approval) => {
      switch (status_approval) {
        case "APP":
          return {
            variant: "outline-success",
            label: "APPROVED",
          };
        case "REJ":
          return {
            variant: "outline-danger",
            label: "REJECT",
          };
        case "REV":
          return {
            variant: "outline-warning",
            label: "REVISI",
          };
        case "VER":
          return {
            variant: "outline-success",
            label: "VERIFIED",
          };
        default:
          return {
            variant: "outline-secondary",
            label: "PENDING",
          };
      }
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Info Peluang</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Info Peluang</ThFixed>
              <Th>Nama Marketing</Th>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th style={{ width: 100 }}>Status Info Peluang</Th>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    <ReadButton
                      onClick={() =>
                        history.push(
                          `/transaksi/info-peluang/detail/${val.id_info_peluang}`,
                          {
                            dataFilter,
                          }
                        )
                      }
                    />
                    {val.status_approval === "REV" && (
                      <UpdateButton
                        onClick={() =>
                          history.push(
                            `/transaksi/info-peluang/ubah/${val.id_info_peluang}`,
                            {
                              dataFilter,
                              aksi: "UPDATE",
                            }
                          )
                        }
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_info_peluang
                      ? DateConvert(new Date(val.tgl_info_peluang)).custom
                      : "-"}
                  </div>
                  <div>{val.no_info_peluang ?? "-"}</div>
                </TdFixed>
                <TdFixed className="text-nowrap">{val.nama_marketing}</TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_proyek ?? "-"}</Td>
                <Td>
                  {
                    <StatusInfoPeluangButton
                      status={val.status_info_peluang}
                      size="sm"
                      disabled={
                        parseInt(val.status_info_peluang) === 3 ||
                        val.status_approval.toUpperCase() !== "APP"
                          ? true
                          : false
                      }
                      onClick={() => {
                        setModalStatusInfoPeluang({ data: val, show: true });
                      }}
                    />
                  }
                </Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      getStatusApprovalLabel(val.status_approval).variant
                    }>
                    {getStatusApprovalLabel(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        <ModalStatusInfoPeluang
          modalStatusPeluang={modalStatusInfoPeluang}
          setModalStatusPeluang={setModalStatusInfoPeluang}
          getInitialData={getInitialData}
          setAlertConfig={setAlertConfig}
          alertConfig={alertConfig}
        />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch}/>
            <FilterButton
              active={dataFilter.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ActionButton
            text="Tambah"
            onClick={() =>
              history.push("/transaksi/info-peluang/tambah", {
                dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default InfoPeluang;
