// React
import React, { PureComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";

// Components
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../../components";
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
} from "../../../../../utilities";

// View Components
import { InfoItems, KopSuratSection, Total, TTDSection } from "./components";

const PrintContent = ({ data, dataBarangJadi }) => {
  const InfoSection = () => {
    return (
      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItems title="Nama" value={data.nama_customer ?? "-"} />
              <InfoItems title="Alamat" value={data.lokasi_pengiriman ?? "-"} />
              <InfoItems
                title="Tgl. SPK"
                value={
                  data.tgl_spk
                    ? DateConvert(new Date(data.tgl_spk)).custom
                    : "-"
                }
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table>
            <tbody>
              <InfoItems title="Telephone" value={data.telepon ?? "-"} />
              <InfoItems title="Email" value={data.email ?? "-"} />
              <InfoItems title="Proyek" value={data.nama_proyek ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const ListItemPurchaseOrderSection = () => {
    let total = 0;

    if (!(dataBarangJadi && dataBarangJadi?.length > 0)) {
      return <DataStatus text="Tidak ada data" />;
    } else {
      return (
        <>
          <b>List Item SPK</b>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Nama Item</Th>
                <ThFixed>Qty</ThFixed>
                <ThFixed>Satuan</ThFixed>
                <ThFixed>Harga Satuan</ThFixed>
                <ThFixed>Sub Total</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {dataBarangJadi.map((val, index) => {
                const harga = val.rounded ? parseFloat(val.rounded) : 0;
                const qty = val.qty_rab ? parseFloat(val.qty_rab) : 0;
                const sub_total = qty * harga;
                total += sub_total;
                const diskon = data.diskon ? parseFloat(data.diskon) : 0;
                const sebelumDiskon = (diskon / 100) * total;
                const hasilDiskon = total - sebelumDiskon;
                const ppn = data.ppn ? parseFloat(data.ppn) : 0;
                const sebelumPpn = (ppn / 100) * hasilDiskon;
                const hasilPpn = hasilDiskon + sebelumPpn;

                return (
                  <>
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_item}</TdFixed>
                      <Td>{val.nama_item}</Td>
                      <Td textRight>{qty}</Td>
                      <Td>{val.satuan ?? "-"}</Td>
                      <Td textRight>
                        {RupiahConvert(harga.toString()).getWithComa}
                      </Td>
                      <Td textRight>
                        {RupiahConvert(sub_total.toString()).getWithComa}
                      </Td>
                    </Tr>
                    {index + 1 === dataBarangJadi.length && (
                      <>
                        <Total title="Total">
                          {RupiahConvert(total.toString()).getWithComa}
                        </Total>
                        <Total
                          title={`Diskon (${
                            DecimalConvert(diskon).getWithComa
                          }%)`}>
                          {
                            RupiahConvert(parseFloat(sebelumDiskon).toString())
                              .getWithComa
                          }
                        </Total>
                        <Total
                          title={`PPN (${DecimalConvert(ppn).getWithComa}%)`}>
                          {
                            RupiahConvert(parseFloat(sebelumPpn).toString())
                              .getWithComa
                          }
                        </Total>
                        <Total title="Total Setelah PPN">
                          {
                            RupiahConvert(parseFloat(hasilPpn).toString())
                              .getWithComa
                          }
                        </Total>
                      </>
                    )}
                  </>
                );
              })}
            </TBody>
          </Table>
        </>
      );
    }
  };

  return (
    <div className="p-4">
      <KopSuratSection />
      <InfoSection />
      <hr />

      <div className="text-center">
        <div>
          <u className="font-weight-bold">Surat Perjanjian Kerja</u>
        </div>
        <div>{data?.no_spk ?? "-"}</div>
      </div>

      <ListItemPurchaseOrderSection />

      <div>
        <b>Catatan</b>
        <div className="border">
          <td dangerouslySetInnerHTML={{ __html: data.catatan ?? "-" }} />
        </div>
      </div>
      <br />

      <div className="mb-5">
        <b>PENTING !</b>
        <div className="border">
          <div>
            Periksalah barang anda dengan order pengiriman disertai dengan
            faktur penyerahan barang dalam rangkap empat (4)
          </div>
          <div>Barang-barang yang sudah diterima tidak dapat dikembalikan</div>
        </div>
      </div>
      {/* <Container> */}
      <div
        className="d-flex justify-content-between"
        style={{ width: 700, margin: "0 auto" }}>
        <TTDSection
          title="Pemberi Pekerjaan"
          value={data?.nama_customer ?? "-"}
          // jabatan="Direktur">
        />

        <TTDSection
          title="Penerima Pekerjaan"
          value="I Dewa Gede Tagel"
          jabatan="Direktur"
        />
      </div>
      {/* </Container> */}
    </div>
  );
};

export default class PrintSPK extends PureComponent {
  render() {
    return (
      <PrintContent
        data={this?.props?.data ?? {}}
        dataBarangJadi={this?.props?.dataBarangJadi ?? []}
      />
    );
  }
}
