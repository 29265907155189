// React
import React, { useState, useEffect, useContext, memo, useRef } from "react";

// Router
import { useHistory, useParams, useLocation } from "react-router-dom";

// Components
import { Row, Col, Card, Nav, Tab } from "react-bootstrap";
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  TextEditor,
} from "components";

// Form
import { useFormik } from "formik";
import * as Yup from "yup";

// Draft JS
import { EditorState, ContentState, convertFromHTML } from "draft-js";

// View Components
import {
  PenawaranContextProvider,
  PenawaranContext,
} from "../PenawaranContext";
import {
  FormPenawaran,
  InfoSection,
  TabFile,
  TabGambar,
  TableBarangJadi,
} from "../Section";

// API
import { PenawaranApi } from "api";

// React to Print
import ReactToPrint from "react-to-print";
import PrintPenawaran from "./PrintPenawaran";
import { InfoItemHorizontal } from "@bhawanadevteam/react-core";

const DetailPenawaran = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const { id_penawaran } = useParams();
  const { state } = useLocation();
  const componentRef = useRef();

  const ContentDetailPenawaran = memo(() => {
    // Hooks
    const { setType } = useContext(PenawaranContext);

    // States
    const [dataPenawaran, setDataPenawaran] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });
    const [textEditorState, setTextEditorState] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            "<p>Dengan hormat,<br />Bersama ini kami sampaikan penawaran harga sebagai berikut :</p>"
          )
        )
      );
    });
    const [textEditorStatePenutup, setTextEditorStatePenutup] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`<p>Catatan :<br />`))
      );
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      PenawaranApi.getSinglePenawaran({ id_penawaran })
        .then((rab) => {
          const mapDataBarangJadi = rab?.data?.data?.detail_barang_jadi?.map(
            (val) =>
              Object({
                ...val,
                satuan: val.nama_satuan_jual,
                qty: val.qty_rab ?? 0,
                harga_satuan: val.unit_cost ?? 0,
                rounded: val.rounded ?? 0,
              })
          );

          setDataPenawaran(rab?.data?.data ?? {});
          setDataBarangJadi(mapDataBarangJadi ?? []);
        })
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };
    const filePenawaran = dataPenawaran?.penawaran_file?.map(
      (val) => val?.path_file
    );

    const formInitialValues = {
      id_penawaran: id_penawaran,
      id_rae: dataPenawaran?.id_rae,
      tgl_rab: dataPenawaran?.tgl_rab ?? "",
      no_rab: dataPenawaran?.no_rab ?? "",
      tgl_penawaran: dataPenawaran?.tgl_penawaran ?? "",
      no_penawaran: dataPenawaran?.no_penawaran ?? "",
      diskon: parseFloat(dataPenawaran?.diskon ?? 0),
      ppn: parseFloat(dataPenawaran?.ppn ?? 0),
      path_gambar: filePenawaran ?? [],
    };
    const formValidationSchema = Yup.object().shape({
      tgl_penawaran: Yup.string().required("Pilih tanggal Penawaran"),
      no_penawaran: Yup.string().required(
        "Pilih tanggal untuk menentukan nomor Penawaran"
      ),
    });
    const formSubmitHandler = (values) => {
      PenawaranApi.save(values)
        .then(() =>
          history.push("/transaksi/penawaran", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    useEffect(() => {
      setNavbarTitle("Penawaran");
      setType("DETAIL");
      getInitialData();

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setTextEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(dataPenawaran?.teks_pembuka ?? "<p></p>")
          )
        )
      );
      setTextEditorStatePenutup(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(dataPenawaran?.teks_penutup ?? "<p></p>")
          )
        )
      );
      return () => {};
    }, [dataPenawaran]);

    const CatatanApproval = ({ data }) => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Penawaran</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {data?.stakeholder?.map((val, index) => (
                <Col key={index} sm>
                  <InfoItem
                    title1={
                      val.status_approval === "VER" ||
                      val.status_approval === "REV"
                        ? `Pemeriksa ${
                            val.approval_level !== "0" ? val.approval_level : ""
                          }`
                        : val.status_approval === "APP"
                        ? "Pengesah"
                        : val.status_approval === "PEN"
                        ? "Pembuat"
                        : "Di Tolak Oleh"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Penawaran</b>

          <div>
            {!isPageLoading && (
              <ReactToPrint
                trigger={() => (
                  <ActionButton className="mb-1 mx-2" text="Cetak" />
                )}
                content={() => componentRef.current}
              />
            )}
            <BackButton
              onClick={() => history.push("/transaksi/penawaran", { ...state })}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataPenawaran} />
              <hr />
              <FormPenawaran formik={formik} type="DETAIL" />
              <TextEditor
                readOnly
                label="Teks Pembuka"
                placeholder="Tuliskan teks pembuka"
                editorState={textEditorState}
              />
              <TableBarangJadi
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                diskon={formik.values.diskon}
                ppn={formik.values.ppn}
                profit={dataPenawaran.profit}
              />
              <TextEditor
                readOnly
                label="Teks Penutup"
                placeholder="Tuliskan teks penutup"
                editorState={textEditorStatePenutup}
              />
              <InfoItemHorizontal
                label="Status Deal"
                text={<b>{dataPenawaran?.status_deal?.toUpperCase() ?? "-"}</b>}
              />
              {console.log(formik?.values)}
              <Card>
                <Tab.Container defaultActiveKey="tab-1">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="tab-1">Gambar</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="tab-2">File</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Header>
                  <Tab.Content>
                    <Tab.Pane eventKey="tab-1">
                      <div className="m-2">
                        <TabGambar readOnly dataGambar={formik} />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab-2">
                      <div className="m-2">
                        <TabFile readOnly dataFile={formik} />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card>

              <CatatanApproval data={dataPenawaran} />

              <div style={{ display: "none" }}>
                <PrintPenawaran
                  ref={componentRef}
                  dataPenawaran={dataPenawaran}
                  dataBarangJadi={dataBarangJadi}
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <PenawaranContextProvider>
      <ContentDetailPenawaran />
    </PenawaranContextProvider>
  );
};

export default DetailPenawaran;
