import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Input, DatePicker } from "components";
import { SuratPerjanjianKerjaApi } from "api";

const FormAdendumSPK = ({ formik, type }) => {
  const today = new Date().toISOString().slice(0, 10);
  const { values, errors, touched, setFieldValue } = formik;

  const getNomorSPK = (date) => {
    SuratPerjanjianKerjaApi.getNomorAdendum({ tanggal: date })
      .then(({ data }) => {
        formik.setFieldValue("tgl_spk", date);
        formik.setFieldValue("no_spk", data.data);
      })
      .catch(() => window.alert("Nomor SPK gagal di generate!"));
  };

  useEffect(() => {
    getNomorSPK(today);
  }, []);

  return (
    <Row>
      <Col sm={6}>
        <DatePicker
          disabled={Boolean(type === "DETAIL")}
          label="Tgl. SPK Adendum"
          placeholderText="Pilih tanggal SPK"
          selected={values.tgl_spk ? new Date(values.tgl_spk) : ""}
          onChange={(val) => {
            const value = val.toISOString().slice(0, 10);
            setFieldValue("tgl_spk", value);
            type === "CREATE" && getNomorSPK(value);
          }}
          error={errors.tgl_spk && touched.tgl_spk && true}
          errorText={errors.tgl_spk && touched.tgl_spk && errors.tgl_spk}
        />
      </Col>
      <Col sm={6}>
        <Input
          label="No. SPK Adendum"
          placeholder="Pilih tanggal untuk menentukan nomor adendum SPK"
          value={values.no_spk}
          readOnly={true}
          error={errors.no_spk && touched.no_spk && true}
          errorText={errors.no_spk && touched.no_spk && errors.no_spk}
        />
      </Col>
    </Row>
  );
};

export default FormAdendumSPK;
