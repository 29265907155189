import React, { memo, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { IoCashOutline, IoAlertOutline } from "react-icons/io5";
import { useQuery } from "react-query";
import { range } from "lodash";
import { Formik } from "formik";
import { Select, Button, ChartBarVertical, ChartLine } from "components2";
import { dateConvert, rupiahConvert, numberConvert } from "utilities2";
import DashboardApi from "./__DashboardApi__";
import { InfoPeluang, PeringkatPeluang } from "./Comp";
import { DatePicker } from "components";

const date = dateConvert();
const rupiah = rupiahConvert();
const number = numberConvert();
const yearNow = date.getYear(new Date());
const monthNow = date.getMonth(new Date());
const defaultEndDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  new Date().getDate()
);

const monthOptions = [
  { label: "Januari", value: "01" },
  { label: "Februari", value: "02" },
  { label: "Maret", value: "03" },
  { label: "April", value: "04" },
  { label: "Mei", value: "05" },
  { label: "Juni", value: "06" },
  { label: "Juli", value: "07" },
  { label: "Agustus", value: "08" },
  { label: "September", value: "09" },
  { label: "Oktober", value: "10" },
  { label: "November", value: "11" },
  { label: "Desember", value: "12" },
];

const yearOptions = range(2000, parseInt(yearNow + 1))
  .map((val) => ({
    label: val,
    value: val,
  }))
  .reverse();

const generateMaxDate = (values) => {
  const date = new Date(values.startMonth);
  date.setMonth(date.getMonth() + 12);
  return date;
};

const convertDate = (startDate, endDate) => {
  const dateFilter = [startDate, endDate];

  const monthName = dateFilter.map((val) => {
    const monthValue = val; // Angka bulan (contoh: 10 untuk Oktober)
    const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini
    const dayOfMonth = date.getDay(new Date()); // Contoh hari 1, Anda bisa menggantinya sesuai kebutuhan

    const todayDate = date.getDetailMonth(
      new Date(currentYear, monthValue - 1, dayOfMonth)
    );

    return todayDate;
  });

  return monthName;
};

const InfoItem = memo(({ date, title, value, loading }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4"
      style={{ height: "100%" }}
    >
      <div
        style={{ fontSize: 30 }}
        className="bg-primary px-3 py-2 my-3 rounded"
      >
        <b>Rp</b>
      </div>
      <div className="d-flex flex-column align-items-end justify-content-end">
        <b style={{ fontSize: 16 }}>{title}</b>
        <b style={{ fontSize: 12 }}>{date}</b>
        {loading ? (
          <small>Memuat data . . .</small>
        ) : (
          <b style={{ fontSize: 30, fontWeight: 1200, lineHeight: 1.2 }}>
            {value}
          </b>
        )}
      </div>
    </div>
  );
});

const Dashboard = ({ setNavbarTitle }) => {
  const defaultMonthName = convertDate(monthNow, date.getMonth(defaultEndDate));
  const [filter, setFilter] = useState({
    startMonth: monthNow,
    endMonth: date.getMonth(defaultEndDate),
    year: date.getYear(new Date()),
    convertMonth: {
      startDate: defaultMonthName[0],
      endDate: defaultMonthName[1],
    },
  });

  const getTotalInfoPeluang = useQuery(
    [
      "dashboard",
      "infoPeluang",
      filter.startMonth,
      filter.endMonth,
      filter.year,
    ],
    () =>
      DashboardApi.getTotalInfoPeluang({
        tahun: filter.year,
        bulan_mulai: filter.startMonth,
        bulan_selesai: filter.endMonth,
      })
  );

  const getPeringkatPeluang = useQuery(
    [
      "dashboard",
      "peringkatPeluang",
      filter.startMonth,
      filter.endMonth,
      filter.year,
    ],
    () =>
      DashboardApi.getPeringkatPeluang({
        tahun: filter.year,
        bulan_mulai: filter.startMonth,
        bulan_selesai: filter.endMonth,
      })
  );

  const getPenawaran = useQuery(
    ["dashboard", "penawaran", filter.startMonth, filter.endMonth, filter.year],
    () =>
      DashboardApi.getPenawaran({
        tahun: filter.year,
        bulan_mulai: filter.startMonth,
        bulan_selesai: filter.endMonth,
      })
  );

  const getPenawaranTahunan = useQuery(
    ["dashboard", "penawaran-tahunan", filter.year],
    () => DashboardApi.getPenawaranTahunan({ tahun: filter.year })
  );
  const getSPKTahunan = useQuery(
    ["dashboard", "spk-tahunan", filter.year],
    () => DashboardApi.getSPKTahunan({ tahun: filter.year })
  );

  useEffect(() => {
    setNavbarTitle("Dashboard");
  }, []);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          startMonth: new Date(),
          endMonth: defaultEndDate,
          year: filter.year,
        }}
        onSubmit={(val) => {
          const startMonth = date.getMonth(new Date(val.startMonth));
          const endMonth = date.getMonth(new Date(val.endMonth));
          const monthName = convertDate(startMonth, endMonth);

          setFilter({
            startMonth,
            endMonth,
            year: val.year,
            convertMonth: {
              startDate: monthName[0],
              endDate: monthName[1],
            },
          });
        }}
      >
        {({ values, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2">
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    dateFormat="MMMM"
                    showMonthYearPicker
                    selected={values.startMonth}
                    startDate={values.startMonth}
                    maxDate={values.startMonth ? generateMaxDate(values) : null}
                    endDate={values.endMonth}
                    onChange={([startMonth, endMonth]) =>
                      setValues({
                        ...values,
                        startMonth: startMonth,
                        endMonth: endMonth,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={yearOptions}
                    defaultValue={yearOptions.find(
                      (val) => val.value === values.year
                    )}
                    onChange={(val) =>
                      setValues((prev) => ({ ...prev, year: val.value }))
                    }
                  />
                </div>
              </div>

              {/* Button */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Purchase request */}
        <Col md={2} className="mb-3">
          <InfoPeluang
            title="Total Info Peluang"
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            value={
              getTotalInfoPeluang?.data?.data?.find(
                (val) => val?.status_info_peluang === 1
              )?.total_info_peluang
            }
            type="info"
            loading={getTotalInfoPeluang.isFetching}
          />
        </Col>
        <Col md={2} className="mb-3">
          <InfoPeluang
            title="Total Info Peluang"
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            value={
              getTotalInfoPeluang?.data?.data?.find(
                (val) => val?.status_info_peluang === 2
              )?.total_info_peluang
            }
            type="perspektif"
            loading={getTotalInfoPeluang.isFetching}
          />
        </Col>
        <Col md={2} className="mb-3">
          <InfoPeluang
            title="Total Info Peluang"
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            value={
              getTotalInfoPeluang?.data?.data?.find(
                (val) => val?.status_info_peluang === 3
              )?.total_info_peluang
            }
            type="target"
            loading={getTotalInfoPeluang.isFetching}
          />
        </Col>

        {/* Info Peringkat Peluang */}
        <Col md={3} className="mb-3">
          <PeringkatPeluang
            title="Peluang Dengan Peringkat Normal"
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            value={
              getPeringkatPeluang?.data?.data?.find(
                (val) => val?.id_peringkat_peluang === 4
              )?.total_peluang
            }
            loading={getPeringkatPeluang.isFetching}
          />
        </Col>

        <Col md={3} className="mb-3">
          <PeringkatPeluang
            title="Peluang Dengan Peringkat Urgent"
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
            value={
              getPeringkatPeluang?.data?.data?.find(
                (val) => val?.id_peringkat_peluang === 1
              )?.total_peluang
            }
            loading={getPeringkatPeluang.isFetching}
          />
        </Col>
      </Row>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Purchase request */}
        <Col md={6} className="mb-3">
          <InfoItem
            loading={getPenawaran.isFetching}
            title="Total Penawaran Deal"
            value={
              getPenawaran.data?.data?.deal
                ? rupiah.getWithComa(getPenawaran.data?.data?.deal)
                : "-"
            }
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
          />
        </Col>

        {/* Info Surat jalan */}
        <Col md={6} className="mb-3">
          <InfoItem
            loading={getPenawaran.isFetching}
            title="Total Penawaran Tidak Deal"
            icon={<IoAlertOutline />}
            value={
              getPenawaran.data?.data?.tidak_deal
                ? rupiah.getWithComa(getPenawaran.data?.data?.tidak_deal)
                : "-"
            }
            date={`${filter?.convertMonth?.startDate} - ${filter?.convertMonth?.endDate} ${filter.year}`}
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6} className="my-3">
          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: "100%" }}
          >
            <div className="text-center">
              <b>Nilai Penawaran Per Bulan Tahun {filter.year}</b>
            </div>
            {getPenawaranTahunan.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartBarVertical
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        label: "Penawaran Deal",
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getPenawaranTahunan?.data?.data?.find(
                              (el) =>
                                el?.bulan_penawaran?.toString() ===
                                val.value.toString()
                            );
                          return findMonth?.deal
                            ? parseFloat(findMonth.deal)
                            : 0;
                        }),
                        backgroundColor: "#0073b9",
                      },
                      {
                        label: "Penawaran Tidak Deal",
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getPenawaranTahunan?.data?.data?.find(
                              (el) =>
                                el?.bulan_penawaran?.toString() ===
                                val.value.toString()
                            );
                          return findMonth?.belum_deal
                            ? parseFloat(findMonth.belum_deal)
                            : 0;
                        }),
                        backgroundColor: "#ed6914",
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
        <Col md={6} className="my-3">
          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: "100%" }}
          >
            <div className="text-center">
              <b>Nilai SPK Per Bulan Tahun {filter.year}</b>
            </div>
            {getSPKTahunan.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  showLegend={false}
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        data: monthOptions.map((val) => {
                          const findMonth = getSPKTahunan?.data?.data?.find(
                            (el) =>
                              el?.bulan_spk?.toString() === val.value.toString()
                          );
                          return findMonth?.nominal_spk
                            ? parseFloat(findMonth.nominal_spk)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
