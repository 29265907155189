import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { IoDocumentTextOutline } from "react-icons/io5";
import FileSaver from "file-saver";
import {
  CRUDLayout,
  Tr,
  Th,
  Td,
  Alert,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  Pagination,
  DatePicker,
  SelectSearch,
  ActionButton,
} from "components";
import { TableNumber, DateConvert, RupiahConvert } from "utilities";
import { LaporanPenawaranApi } from "api";

const LaporanPenawaran = ({ setNavbarTitle }) => {
  const TODAY = new Date().toISOString().slice(0, 10);
  const dropdownStatus = [
    { value: null, label: "Semua Data" },
    { value: "deal", label: "Deal" },
    { value: "belum_deal", label: "Belum Deal" },
  ];
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    tanggal_start: TODAY,
    tanggal_end: TODAY,
    status_deal: null,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  });

  const mapDataFromServer = (data) => {
    const uniqNumber = [];

    // const mappedData = data.map((item) => {
    // if (!uniqNumber.includes(item.no_penawaran)) {
    //   uniqNumber.push(item.no_penawaran);
    //   return item;
    // }

    //   return Object({
    //     nama_item: item.nama_item,
    //     qty_rab: item.qty_rab,
    //     nama_satuan: item.nama_satuan,
    //   });
    // });

    return data;
  };

  const handleFetchReport = () => {
    setLoading(true);

    const query = {
      ...form,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    };

    LaporanPenawaranApi.getPage(query)
      .then(({ data }) => {
        setData(mapDataFromServer(data.data ?? []));
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          variant: "danger",
          text: "Tidak dapat memuat data!",
          show: true,
        })
      )
      .finally(() => setLoading(false));
  };

  const handleButtonExport = (tipe) => {
    const query = { ...form, tipe };

    LaporanPenawaranApi.getPenawaranExport(query).then(({ data }) => {
      const ext = tipe === "excel" ? "xlsx" : "pdf";
      FileSaver.saveAs(data.data, `Laporan_Penawaran.${ext}`);
    });
  };

  useEffect(() => {
    setNavbarTitle("Laporan Penawaran");
    handleFetchReport();
  }, [setNavbarTitle, paginationConfig.page, paginationConfig.dataLength]);

  // Reset page on form filter change
  useEffect(
    () =>
      setPaginationConfig({
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }),
    [form]
  );

  const ExportButton = ({
    variant = "outline-success",
    text = "Export EXCEL",
    onClick,
  }) => {
    return (
      <Button variant={variant} onClick={onClick} className="mx-1 mb-1">
        <IoDocumentTextOutline size={18} />
        <span className="ml-2 text-uppercase">{text}</span>
      </Button>
    );
  };

  const TableSection = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. Penawaran</ThFixed>
              <ThFixed>No. Penawaran</ThFixed>
              <Th>Customer</Th>
              <Th>Status Deal</Th>
              <Th>Description (Barang Jadi)</Th>
              <Th style={{ minWidth: 90 }}>Qty.</Th>
              <Th>Proyek</Th>
              <Th>Keterangan</Th>
              <Th>Grand Total</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((item, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </TdFixed>
                  <Td className="text-center">
                    {item.tgl_penawaran
                      ? item.tgl_penawaran
                        ? DateConvert(new Date(item.tgl_penawaran)).custom
                        : "-"
                      : ""}
                  </Td>
                  <Td>{item.no_penawaran ? item.no_penawaran : ""}</Td>
                  <Td>{item.nama_customer ? item.nama_customer : ""}</Td>
                  <Td>
                    {item.status_deal
                      ? item.status_deal === "deal"
                        ? "Deal"
                        : " Belum Deal"
                      : ""}
                  </Td>
                  <Td>{item.nama_item ?? "-"}</Td>
                  <Td>{`${parseFloat(item.qty_rab) || "-"} ${
                    item.nama_satuan ?? ""
                  } `}</Td>
                  <Td>{item.nama_proyek ? item.nama_proyek : ""}</Td>
                  <Td>{item.keterangan ?? "-"}</Td>
                  <Td textRight>
                    {RupiahConvert(item.total ?? "0").getWithComa}
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex flex-wrap px-1 py-2 mx-1 mt-1 mb-3 bg-white rounded-lg">
        {/* Periode */}
        <Col md={6}>
          <DatePicker
            selectsRange
            label="Tgl. Penawaran"
            placeholderText="Pilih tanggal penawaran"
            startDate={form.tanggal_start ? new Date(form.tanggal_start) : ""}
            endDate={form.tanggal_end ? new Date(form.tanggal_end) : ""}
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setForm({
                ...form,
                tanggal_start: startDate,
                tanggal_end: endDate,
              });
            }}
            monthsShown={2}
          />
        </Col>

        {/* Filter */}
        <Col
          md={6}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <span className="mt-1">Filter By:</span>
          <SelectSearch
            wrapperClassName="w-25 mx-2"
            label="Status Deal"
            placeholder="Pilih status deal"
            defaultValue={{ values: null, label: "Semua Data" }}
            option={dropdownStatus ?? []}
            onChange={(val) => {
              setForm((prev) => ({ ...prev, status_deal: val.value }));
            }}
          />
          <ActionButton
            className="ml-2 mt-3 p-2"
            size="sm"
            text="LIHAT DATA"
            onClick={handleFetchReport}
          />
        </Col>
      </Row>

      <Alert
        showCloseButton={true}
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {!isLoading && data.length > 0 && (
        <Row className="d-flex justify-content-end align-items-center mx-1 mt-4 mb-1">
          <ExportButton
            variant="outline-danger"
            text="Export PDF"
            onClick={() => handleButtonExport("pdf")}
          />
          <ExportButton onClick={() => handleButtonExport("excel")} />
        </Row>
      )}

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat data ..." />
      ) : (
        <TableSection />
      )}
    </CRUDLayout>
  );
};

export default LaporanPenawaran;
