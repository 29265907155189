// React
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// Bootstrap
import { Card, Modal, Form } from "react-bootstrap";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import axios from "axios";
import { RegistrasiPeluangApi } from "api";

// Component
import {
  CRUDLayout,
  Input,
  DataStatus,
  Alert,
  BackButton,
  ActionButton,
  SelectSearch,
  TextArea,
  THead,
  TBody,
  Tr,
  Th,
  ThFixed,
  DeleteButton,
  Td,
  TdFixed,
  DatePicker,
  TextEditor,
  InputQtyDecimal,
} from "components";

// View
import AnalisaBarangJadiPeluang from "./AnalisaBarangJadiPeluang";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { DecimalConvert } from "utilities";

const UbahRegistrasiPeluang = ({ setNavbarTitle }) => {
  const title = "Peluang";
  // USE HISTORY
  const history = useHistory();

  let { id } = useParams();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // DATA
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataNoBaru, setDataNoBaru] = useState("");
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataProyek, setDataProyek] = useState([]);
  const [dataPeringkatPeluang, setDataPeringkatPeluang] = useState([]);
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [dataUnitProduksi, setDataUnitProduksi] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // Modal
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const [textEditorState, setTextEditorState] = useState(() => {
    return EditorState.createEmpty();
  });

  const updateEditorState = (value, setFieldValue, editor) => {
    setTextEditorState(value);
    const convertData = convertToRaw(value.getCurrentContent());

    setFieldValue(editor, draftToHtml(convertData));
  };

  const getData = () => {
    setIsLoading(true);

    axios
      .all([
        RegistrasiPeluangApi.getSingle(id),
        RegistrasiPeluangApi.getCustomer(),
        RegistrasiPeluangApi.getPeringkatPeluang(),
        RegistrasiPeluangApi.getBarangJadi(),
        RegistrasiPeluangApi.getProyek(),
        RegistrasiPeluangApi.getUnitProduksi(),
      ])
      .then(
        axios.spread((data, customer, peringkat, barang, proyek, unit) => {
          setData(data.data.data);
          setDataDetail(data.data.data.detail);
          setDataCustomer(customer.data.data);
          setDataPeringkatPeluang(peringkat.data.data);
          setDataBarangJadi(barang.data.data);
          setDataProyek(proyek.data.data);
          setDataUnitProduksi(unit.data.data);
        })
      )
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: `Tidak dapat memuat data!`,
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getDetail = ({ loading: loading = true }) => {
    setIsLoading(loading);
    RegistrasiPeluangApi.getSingle(id)
      .then((res) => setDataDetail(res.data.data.detail))
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setIsLoading(false);
    setNavbarTitle("Ubah " + title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data.lain_lain ?? "<p></p>")
        )
      )
    );
  }, [data]);

  const ModalSection = ({ processedData, modalConfig, setModalConfig }) => {
    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === "analisa" ? "xl" : "md"}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AnalisaBarangJadiPeluang processedData={processedData} />
        </Modal.Body>
      </Modal>
    );
  };

  const setBarangJadi = (id_item_buaso, qty) => {
    if (id_item_buaso != "" && qty != "") {
      const value = {
        id_peluang: data.id_peluang,
        id_item_buaso: id_item_buaso,
        qty: qty,
      };

      RegistrasiPeluangApi.createDetail(value)
        .then(() => getDetail({ loading: false }))
        .catch((err) =>
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          })
        );
    }
  };

  const hapusBarangJadi = (id_peluang_detail) => {
    RegistrasiPeluangApi.deleteDetail({ id_peluang_detail: id_peluang_detail })
      .then(() => getDetail({ loading: false }))
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: "danger",
          text: `Hapus data gagal! (${err.response.data.message})`,
        });
      });
  };

  // INITIAL VALUES FORM TAMBAH DATA TIPE BARANG JADI
  const formInitialValues = {
    id_info_peluang: data.id_info_peluang,
    id_peluang: data.id_peluang,
    tgl_peluang: data.tgl_peluang,
    no_peluang: data.no_peluang,
    id_customer: data.id_customer,
    nama_customer: data.nama_customer,
    id_proyek: data.id_proyek ?? "",
    nama_proyek: data.nama_proyek,
    att: data.att,
    id_peringkat_peluang: data.id_peringkat_peluang,
    nama_peringkat_peluang: data.nama_peringkat_peluang,
    keterangan_pengiriman: data.keterangan_pengiriman,
    lokasi_pengiriman: data.lokasi_pengiriman,
    lain_lain: data.lain_lain,
    id_item_buaso: "",
    kode_item: "",
    nama_item: "",
    qty: "",
    id_unit_produksi: data.id_unit_produksi,
    nama_unit_produksi: data.nama_unit_produksi,
  };

  // FORMIK VALIDATION SCHEMA TIPE BARANG JADI DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    tgl_peluang: Yup.date().required("Tanggal Peluang wajib diisi"),
    id_customer: Yup.string().required("Customer wajib diisi"),
    id_proyek: Yup.string().required("Proyek wajib diisi"),
    id_peringkat_peluang: Yup.string().required(
      "Peringkat Peluang wajib diisi"
    ),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values) => {
    RegistrasiPeluangApi.update(values)
      .then(() => {
        history.push("/transaksi/registrasi-peluang", {
          registerAlertConfig: {
            variant: "primary",
            text: "Ubah data berhasil!",
          },
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: "danger",
          text: `Ubah data gagal! (${err.response.data.message})`,
        });
      })
      .finally(() => {
        // SHOW ALERT
        setShowAlert(true);
      });
  };

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card className="pb-3 mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Ubah Data {title}</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Card.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <DatePicker
                          label="Tanggal Info Peluang"
                          name="tgl_info_peluang"
                          selected={
                            data?.tgl_info_peluang
                              ? new Date(data?.tgl_info_peluang)
                              : ""
                          }
                          readOnly
                          onChange={(val) => {
                            const value = val.toISOString().slice(0, 10);
                            setFieldValue("tgl_peluang", value);
                          }}
                          error={
                            errors.tgl_peluang && touched.tgl_peluang && true
                          }
                          errorText={errors.tgl_peluang}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label="No Info Peluang"
                          type="text"
                          value={data?.no_info_peluang}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          label="Nama Marketing"
                          type="text"
                          value={data?.nama_marketing}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <DatePicker
                          label="Tanggal Peluang"
                          name="tgl_peluang"
                          selected={
                            values.tgl_peluang
                              ? new Date(values.tgl_peluang)
                              : ""
                          }
                          onChange={(val) => {
                            const value = val.toISOString().split("T")[0];
                            setFieldValue("tgl_peluang", value);
                          }}
                          error={
                            errors.tgl_peluang && touched.tgl_peluang && true
                          }
                          errorText={errors.tgl_peluang}
                        />
                      </div>

                      <div className="col-md-6">
                        <Input
                          label="No Peluang"
                          type="text"
                          name="no_peluang"
                          value={values.no_peluang}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="row">
                      <div className="col-md-6">
                        <SelectSearch
                          isDisabled
                          label="Customer"
                          name="id_customer"
                          onChange={(val) =>
                            setFieldValue("id_customer", val.value)
                          }
                          option={dataCustomer.map((val) => {
                            return {
                              value: val.id_customer,
                              label: val.nama_customer,
                            };
                          })}
                          defaultValue={{
                            value: values.id_customer ? values.id_customer : "",
                            label: values.nama_customer
                              ? values.nama_customer
                              : "Pilih Customer",
                          }}
                          error={
                            errors.id_customer && touched.id_customer && true
                          }
                          errorText={
                            errors.id_customer &&
                            touched.id_customer &&
                            errors.id_customer
                          }
                        />

                        <SelectSearch
                          isDisabled
                          label="Proyek"
                          name="id_proyek"
                          onChange={(val) =>
                            setFieldValue("id_proyek", val.value)
                          }
                          option={dataProyek.map((val) =>
                            Object({
                              value: val.id_proyek,
                              label: val.nama_proyek,
                            })
                          )}
                          defaultValue={{
                            value: values.id_proyek ? values.id_proyek : "",
                            label: values.nama_proyek
                              ? values.nama_proyek
                              : "Pilih Proyek",
                          }}
                          error={errors.id_proyek && touched.id_proyek && true}
                          errorText={
                            errors.id_proyek &&
                            touched.id_proyek &&
                            errors.id_proyek
                          }
                        />

                        <SelectSearch
                          label="Peringkat Peluang"
                          name="id_peringkat_peluang"
                          onChange={(val) =>
                            setFieldValue("id_peringkat_peluang", val.value)
                          }
                          option={dataPeringkatPeluang.map((val) => {
                            return {
                              value: val.id_peringkat_peluang,
                              label: val.nama_peringkat_peluang,
                            };
                          })}
                          defaultValue={{
                            value: values.id_peringkat_peluang
                              ? values.id_peringkat_peluang
                              : "",
                            label: values.nama_peringkat_peluang
                              ? values.nama_peringkat_peluang
                              : "Pilih Peringkat Peluang",
                          }}
                          error={
                            errors.id_peringkat_peluang &&
                            touched.id_peringkat_peluang &&
                            true
                          }
                          errorText={
                            errors.id_peringkat_peluang &&
                            touched.id_peringkat_peluang &&
                            errors.id_peringkat_peluang
                          }
                        />

                        <Input
                          label="ATT"
                          type="text"
                          name="att"
                          value={values.att}
                          onChange={handleChange}
                        />

                        <TextArea
                          label="Keterangan Pengiriman"
                          name="keterangan_pengiriman"
                          value={values.keterangan_pengiriman}
                          error={
                            errors.keterangan_pengiriman &&
                            touched.keterangan_pengiriman &&
                            true
                          }
                          errorText={errors.keterangan_pengiriman}
                          onChange={handleChange}
                        />

                        <TextArea
                          label="Lokasi Pengiriman"
                          name="lokasi_pengiriman"
                          value={values.lokasi_pengiriman}
                          error={
                            errors.lokasi_pengiriman &&
                            touched.lokasi_pengiriman &&
                            true
                          }
                          errorText={errors.lokasi_pengiriman}
                          onChange={handleChange}
                        />
                        <SelectSearch
                          label="Unit Produksi"
                          name="id_unit_produksi"
                          onChange={(val) =>
                            setFieldValue("id_unit_produksi", val.value)
                          }
                          option={dataUnitProduksi.map((val) => {
                            return {
                              value: val.id_unit_produksi,
                              label: val.nama_unit_produksi,
                            };
                          })}
                          defaultValue={{
                            value: values.id_unit_produksi
                              ? values.id_unit_produksi
                              : "",
                            label: values.nama_unit_produksi
                              ? values.nama_unit_produksi
                              : "Pilih Unit Produksi",
                          }}
                          error={
                            errors.id_unit_produksi &&
                            touched.id_unit_produksi &&
                            true
                          }
                          errorText={
                            errors.id_unit_produksi &&
                            touched.id_unit_produksi &&
                            errors.id_unit_produksi
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextEditor
                          label="Informasi Lainya"
                          placeholder="Masukan Informasi Lainya"
                          editorState={textEditorState}
                          editorStyle={{ height: 350 }}
                          onEditorStateChange={(val) => {
                            updateEditorState(val, setFieldValue, "lain_lain");
                          }}
                          error={errors.lain_lain && touched.lain_lain && true}
                          errorText={
                            errors.lain_lain &&
                            touched.lain_lain &&
                            errors.lain_lain
                          }
                        />
                      </div>
                    </div>
                    <hr></hr>

                    <div className="row mb-2">
                      <div className="col-md-6">
                        <SelectSearch
                          label="Barang Jadi"
                          name="id_item_buaso"
                          placeholder="Pilih Jenis Barang Jadi"
                          onChange={(val) => {
                            setFieldValue("id_item_buaso", val.value);
                            setFieldValue("nama_item", val.label);
                            setFieldValue("kode_item", val.kode);
                            setFieldValue(
                              "id_item_atribut",
                              val.id_item_atribut
                            );
                          }}
                          option={dataBarangJadi?.map((val) => {
                            return {
                              value: val.id_item_buaso,
                              label: `${val.kode_item} | ${val.nama_item}`,
                              kode: val.kode_item,
                            };
                          })}
                          defaultValue={{
                            value: "",
                            label: "Pilih Barang Jadi",
                          }}
                          error={
                            errors.id_item_buaso &&
                            touched.id_item_buaso &&
                            true
                          }
                          errorText={
                            errors.id_item_buaso &&
                            touched.id_item_buaso &&
                            errors.id_item_buaso
                          }
                        />
                      </div>

                      <div className="col-md-3">
                        <InputQtyDecimal
                          label="Qty"
                          value={values.qty}
                          onChange={(e) => {
                            setFieldValue("qty", e);
                          }}
                        />
                      </div>

                      <div className="col-md-3">
                        <br></br>
                        <ActionButton
                          type="button"
                          className="btn btn-primary btn-block"
                          size="md"
                          text="Tambah Data"
                          onClick={() => {
                            setBarangJadi(values.id_item_buaso, values.qty);
                          }}
                        />
                      </div>
                    </div>

                    <CRUDLayout.Table>
                      <THead>
                        <tr className="bg-light">
                          <ThFixed>No</ThFixed>
                          <Th style={{ width: 100 }}>Kode Barang Jadi</Th>
                          <Th>Barang Jadi</Th>
                          <Th>Jenis Barang Jadi</Th>
                          <Th>Satuan</Th>
                          <Th>Qty</Th>
                          <ThFixed>Aksi</ThFixed>
                        </tr>
                      </THead>
                      <TBody>
                        {dataDetail?.length > 0 ? (
                          dataDetail?.map((val, index) => (
                            <Tr key={index}>
                              <TdFixed>{index + 1}</TdFixed>
                              <TdFixed>{val.kode_item}</TdFixed>
                              <Td>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProcessedData({
                                      ...val,
                                      index: index,
                                      id_barang_jadi: val.id_item_atribut,
                                    });
                                    setModalConfig({
                                      show: true,
                                      type: "analisa",
                                      title: (
                                        <span className="text-primary">
                                          Analisa Barang Jadi Peluang
                                        </span>
                                      ),
                                    });
                                  }}
                                >
                                  {val.nama_item}
                                </a>
                              </Td>
                              <Td>{val.nama_jenis}</Td>
                              <Td>{val.nama_satuan_jual}</Td>
                              <Td>
                                <div className="text-right">
                                  {DecimalConvert(val.qty).getWithComa}
                                </div>
                              </Td>
                              <TdFixed>
                                <DeleteButton
                                  onClick={() =>
                                    hapusBarangJadi(val.id_peluang_detail)
                                  }
                                />
                              </TdFixed>
                            </Tr>
                          ))
                        ) : (
                          <Tr>
                            <Td colSpan={7} className="py-3 text-center">
                              <span style={{ fontSize: 14 }}>
                                Tidak ada data
                              </span>
                            </Td>
                          </Tr>
                        )}
                      </TBody>
                    </CRUDLayout.Table>

                    <div className="text-right mt-2">
                      <ActionButton
                        type="submit"
                        className="btn btn-primary text-white"
                        size="md"
                        text="Simpan"
                        loading={isSubmitting}
                      />
                    </div>
                  </Card.Body>
                </form>
              </>
            )}
          </Formik>
        )}
      </Card>

      <ModalSection
        processedData={processedData}
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />
    </CRUDLayout>
  );
};
export default UbahRegistrasiPeluang;
