const TTDSection = ({ title, value, jabatan }) => {
	return (
		<div className="text-center">
			<div>{title}</div>
			<div style={{ height: 80 }}></div>
			<div>{value}</div>
			<div>{jabatan}</div>
		</div>
	)
}

export default TTDSection