import {
  createContext,
  useState
} from 'react'

export const PenawaranContext = createContext()

export const PenawaranContextProvider = ({ children }) => {
  const [indexAnalisa, setIndexAnalisa] = useState('')
  const [type, setType] = useState('')
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([])

  return (
    <PenawaranContext.Provider value={{
      indexAnalisa,
      type,
      dataAnalisaBarangJadi,
      setIndexAnalisa,
      setType,
      setDataAnalisaBarangJadi,
    }}>
      {children}
    </PenawaranContext.Provider>
  )
}
