import { InfoPeluangApi } from "api";
import { ActionButton } from "components";
import { Formik } from "formik";
import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { DateConvert } from "utilities";
import {
  formInitialValues,
  formSubmitHandler,
  formValidationSchema,
} from "../Utils";
import InfoItem from "./InfoItem";
import StatusInfoPeluangButton from "./StatusInfoPeluangButton";

const ModalStatusInfoPeluang = ({
  setModalStatusPeluang,
  modalStatusPeluang,
  getInitialData,
  setAlertConfig,
  alertConfig,
}) => {
  const submitHandler = (values) => {
    const savingValue = formSubmitHandler(values, "STATUS");

    InfoPeluangApi.updateStatus(savingValue)
      .then(() => {
        setModalStatusPeluang({ ...modalStatusPeluang, show: false });
        getInitialData();
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: "danger",
          text: `Tambah data gagal! (${err.response.data.message})`,
        });
      })
      .finally(() => {
        // SHOW ALERT

        setAlertConfig({
          variant: "primary",
          text: "Ubah Status data Berhasil!",
          show: true,
        });
      });
  };

  return (
    <div>
      <Modal
        show={modalStatusPeluang.show}
        onHide={() =>
          setModalStatusPeluang({ ...modalStatusPeluang, show: false })
        }
        size="xl">
        <Modal.Header closeButton>Ubah Status Info Peluang</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={formInitialValues(modalStatusPeluang.data)}
            validationSchema={formValidationSchema}
            onSubmit={submitHandler}>
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <Row>
                  <Col lg>
                    <InfoItem
                      title="Tgl. Info Peluang"
                      value={
                        values.tgl_info_peluang
                          ? DateConvert(new Date(values.tgl_info_peluang))
                              .detail
                          : "-"
                      }
                    />
                    <InfoItem
                      title="No. Info Peluang"
                      value={values.no_info_peluang ?? "-"}
                    />
                    <InfoItem
                      title="Customer"
                      value={values.nama_customer ?? "-"}
                    />
                    <InfoItem
                      title="Proyek"
                      value={values.nama_proyek ?? "-"}
                    />
                  </Col>
                  <Col lg>
                    <InfoItem
                      title="Nama Marketing"
                      value={values.nama_marketing ?? "-"}
                    />
                    <InfoItem
                      title="Jabatan"
                      value={values.nama_jabatan ?? "-"}
                    />
                    <InfoItem
                      title="Unit Organisasi"
                      value={values.nama_unit_organisasi ?? "-"}
                    />
                  </Col>
                </Row>
                <hr />
                <div>
                  <div>Status Info Peluang</div>
                  <div
                    className={
                      values.status_info_peluang === "1"
                        ? "text-warning"
                        : values.status_info_peluang === "2"
                        ? "text-success"
                        : "text-primary"
                    }>
                    <b>{String(values.status_peluang ?? "-").toUpperCase()}</b>
                  </div>
                  <div>
                    {
                      DateConvert(new Date(values.tgl_status_info_peluang))
                        .custom
                    }
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-center">
                  <ActionButton
                    variant="outline-dark"
                    text="Batal"
                    className="mr-2"
                    onClick={() =>
                      setModalStatusPeluang({
                        ...modalStatusPeluang,
                        show: false,
                      })
                    }
                  />
                  <StatusInfoPeluangButton
                    size="md"
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                    status={String(
                      parseInt(values.status_info_peluang) + 1 ?? "0"
                    )}
                  />
                </div>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalStatusInfoPeluang;
