import Services from "services";

class LaporanPeluangApi {
  getPage(params) {
    return Services.get("/laporan_peluang/page", { params });
  }

  getPeringkatPeluang() {
    return Services.get("/dropdown/peringkat_peluang");
  }

  getCustomer() {
    return Services.get("/dropdown/customer");
  }

  getPeluangExport(params) {
    return Services.get("/peluang_report", { params });
  }
}

export default new LaporanPeluangApi();
