import Services from "../../../services";

class RegistrasiPeluangApi {
  getPage(params) {
    return Services.get("/peluang/page", { params });
  }

  getInfoPeluangPage(params) {
    return Services.get("/peluang/info_peluang/page", { params });
  }

  create(data) {
    return Services.post("/peluang", data);
  }

  getSingleInfoPeluang(data) {
    return Services.get(`/peluang/single_info_peluang?id_info_peluang=${data}`);
  }

  createDetail(data) {
    return Services.post("/peluang/detail", data);
  }

  getSingle(data) {
    return Services.get(`/peluang/single?id_peluang=${data}`);
  }

  update(data) {
    return Services.put("/peluang", data);
  }

  hide(data) {
    return Services.put("/peluang/hide", data);
  }

  show(data) {
    return Services.put("/peluang/show", data);
  }

  delete(data) {
    return Services.post("/peluang/delete", data);
  }

  deleteDetail(data) {
    return Services.post("/peluang/detail_delete", data);
  }

  getCustomer() {
    return Services.get("/peluang/customer");
  }

  getProyek() {
    return Services.get("/dropdown/proyek");
  }

  getPeringkatPeluang() {
    return Services.get("/peluang/peringkat_peluang");
  }

  getNoBaru(data) {
    return Services.get(`/peluang/no_baru?tanggal=${data}`);
  }

  getBarangJadi() {
    return Services.get("/peluang/barang_jadi");
  }

  getAnalisa(params) {
    return Services.get("/peluang/analisa", { params });
  }

  getUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }

  getDropdownPeringkatPeluang() {
    return Services.get("/peluang/peringkat_peluang");
  }

  getDropdownCustomer() {
    return Services.get("/peluang/customer");
  }

  getDropdownProyek() {
    return Services.get("/dropdown/proyek");
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }
}

export default new RegistrasiPeluangApi();
