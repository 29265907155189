import React, { useState, useEffect, useContext, memo } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  TextEditor,
} from "components";
import { SPKContextProvider, SPKContext } from "../SPKContext";
import {
  FormAdendumSPK,
  FormSPK,
  InfoSection,
  TableBarangJadi,
  TableBarangJadiAdendum,
} from "../Section";
import { SuratPerjanjianKerjaApi } from "api";

const AdendumSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_spk } = useParams();
  const { state } = useLocation();

  const ContentUbahSPK = memo(() => {
    const { setType, dataAnalisaBarangJadi, setDataAnalisaBarangJadi } =
      useContext(SPKContext);
    const [dataSPK, setDataSPK] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });
    const [textEditorStateCatatan, setTextEditorStateCatatan] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`<p>Catatan :<br />`))
      );
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      SuratPerjanjianKerjaApi.getSingle({ id_spk })
        .then((spk) => {
          const mapDataBarangJadi = spk?.data?.data?.detail_barang_jadi?.map(
            (val) => {
              return Object({
                ...val,
                satuan: val.satuan,
                qty: val.qty ?? 0,
                harga_satuan: val.unit_cost ?? 0,
                rounded: val.rounded,
                qty_adendum: val.qty_adendum ?? 0,
              });
            }
          );

          setDataSPK(spk?.data?.data ?? {});
          setDataBarangJadi(mapDataBarangJadi ?? []);
        })
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_penawaran: dataSPK?.id_penawaran ?? "",
      tgl_spk: dataSPK?.tgl_spk ?? "",
      no_spk: dataSPK?.no_spk ?? "",
      profit: parseFloat(dataSPK?.profit ?? 0),
      id_referensi_spk: id_spk,
      catatan: dataSPK.catatan,
      diskon: dataSPK.diskon,
      ppn: dataSPK.ppn,
    };
    const formValidationSchema = Yup.object().shape({
      tgl_spk: Yup.string().required("Pilih tanggal SPK adendum"),
      no_spk: Yup.string().required(
        "Pilih tanggal untuk menentukan nomor SPK adendum"
      ),
    });
    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        detail: dataBarangJadi.map((val) => ({
          id_barang_jadi: val.id_barang_jadi,
          unit_cost: val.unit_cost,
          qty: val.qty_adendum,
          rounded: val.rounded,
        })),
      };
      console.log(dataBarangJadi);
      SuratPerjanjianKerjaApi.saveAdendum(finalValues)
        .then(() =>
          history.push("/transaksi/spk", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data Adendum berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };

    const updateEditorState = (value, setFieldValue, editor) => {
      setTextEditorStateCatatan(value);
      const convertData = convertToRaw(value.getCurrentContent());
      setFieldValue("catatan", draftToHtml(convertData));
    };

    useEffect(() => {
      setNavbarTitle("Surat Perjanjian Kerja");
      setType("UPDATE");
      getInitialData();
    }, []);

    useEffect(() => {
      setTextEditorStateCatatan(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(dataSPK?.catatan ?? "<p></p>")
          )
        )
      );
      return () => {};
    }, [dataSPK]);

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Adendum Data SPK</b>
          <BackButton
            onClick={() => history.push("/transaksi/spk", { ...state })}
          />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {(formik) => (
                  <>
                    <InfoSection data={dataSPK} action="adendum" />
                    <hr />
                    <FormAdendumSPK formik={formik} type="UPDATE" />

                    <TableBarangJadiAdendum
                      dataBarangJadi={dataBarangJadi}
                      setDataBarangJadi={setDataBarangJadi}
                    />

                    <TextEditor
                      label="Catatan"
                      placeholder="Tuliskan teks catatan"
                      editorState={textEditorStateCatatan}
                      onEditorStateChange={(val) =>
                        updateEditorState(val, formik.setFieldValue)
                      }
                      error={
                        formik.errors.catatan && formik.touched.catatan && true
                      }
                      errorText={
                        formik.errors.catatan &&
                        formik.touched.catatan &&
                        formik.errors.catatan
                      }
                    />
                    <div className="mt-4 d-flex justify-content-end align-items-center">
                      <ActionButton
                        variant="success"
                        text="Ubah Data SPK"
                        loading={formik.isSubmitting}
                        onClick={formik.handleSubmit}
                      />
                    </div>
                  </>
                )}
              </Formik>
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <SPKContextProvider>
      <ContentUbahSPK />
    </SPKContextProvider>
  );
};

export default AdendumSPK;
