const DateConvert = (dateParams) => {
  const day = dateParams.getDay();
  const date = dateParams.getDate();
  const month = dateParams.getMonth();
  const year = dateParams.getFullYear();

  const newDate = date.toString().length < 2 ? `0${date}` : date;
  const checkMonth = month + 1;
  const newMonth =
    checkMonth.toString().length < 2 ? `0${checkMonth}` : checkMonth;

  const dayValue = [
    'Minggu',
    'Senin',
    'Selasa',
    'Rabu',
    'Kamis',
    'Jumat',
    'Sabtu',
  ];

  const monthValue = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];

  return {
    detail: `${dayValue[day]}, ${date} ${monthValue[month]} ${year}`,
    detailDay: `${dayValue[day]}`,
    detailMonth: `${monthValue[month]}`,
    default: `${year}-${newMonth}-${newDate}`,
    defaultDay: newDate,
    defaultMonth: newMonth,
    defaultYear: year,
    custom: `${newDate}/${month + 1}/${year}`,
    defaultDMY: `${newDate}/${newMonth}/${year}`, // => 01/01/2000
  };
};

export default DateConvert;
