import React, { useEffect, useState, useContext, memo } from "react";
import { Modal, Spinner, ButtonGroup } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { IoAdd } from "react-icons/io5";
import {
  Alert,
  SelectSearch,
  Input,
  ActionButton,
  Table,
  Td,
  InputQtyDecimal,
  NumberFormat,
  DataStatus,
} from "components";
import AnalisaBarangJadiRAE from "../AnalisaBarangJadi";
import { DecimalConvert, RupiahConvert } from "utilities";
import { SPKContext } from "../SPKContext";
import { SuratPerjanjianKerjaApi } from "api";
import {
  IoPencilOutline,
  IoTrashBinOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from "react-icons/io5";

const TableForm = ({
  data,
  type,
  formik,
  dataBarangJadi,
  editIndex,
  setDataBarangJadi,
  dropdownBarangJadi,
  setEditIndex,
  loadingDropdown,
}) => {
  console.log(data);

  const tableIntialValues = {
    id_barang_jadi: data?.id_barang_jadi ?? undefined,
    nama_item: data?.nama_item ?? "",
    qty_adendum: data?.qty_adendum
      ? parseFloat(data?.qty_adendum ?? 0)
      : parseFloat(data?.qty ?? 0),
    unit_cost: parseFloat(data?.unit_cost ?? 0),
    satuan: data?.satuan ?? "",
    rounded: data?.rounded
      ? parseFloat(
          parseFloat(data?.unit_cost ?? 0) +
            (parseFloat(formik?.values?.profit ?? 0) *
              parseFloat(data?.unit_cost ?? 0)) /
              100
        )
      : 0,
    qty: data?.qty ?? 0,
    qty_direalisasi: data?.qty_direalisasi ?? 0,
    qty_sedang_diproduksi: data?.qty_sedang_diproduksi ?? 0,
    kode_item: data?.kode_item ?? "",
  };

  const tableValidationSchema = Yup.object().shape({
    id_barang_jadi: Yup.string().required("Barang jadi wajib diisi"),
  });

  const updateDataTable = (value) => {
    console.log("val", value);
    const mapDataUpdate = dataBarangJadi.map((val, ind) =>
      editIndex === ind ? value : val
    );
    setDataBarangJadi(mapDataUpdate);
    setEditIndex(null);
  };

  const tableSubmitHandler = (value) => {
    type === "create" && setDataBarangJadi([...dataBarangJadi, value]);
    type === "update" && updateDataTable(value);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={tableIntialValues}
      onSubmit={tableSubmitHandler}
      validationSchema={tableValidationSchema}
    >
      {({
        values,
        setValues,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
      }) => {
        return (
          <tr>
            <td colSpan="2">
              <SelectSearch
                option={dropdownBarangJadi}
                defaultValue={{
                  label: values.nama_item,
                  value: values.id_barang_jadi,
                }}
                loading={loadingDropdown}
                onChange={(val) =>
                  setValues({
                    ...values,
                    id_barang_jadi: val.value,
                    nama_item: val.label,
                    satuan: val.satuan,
                    kode_item: val.kode_item,
                  })
                }
                error={errors.id_barang_jadi && touched.id_barang_jadi && true}
                errorText={
                  errors.id_barang_jadi &&
                  touched.id_barang_jadi &&
                  errors.id_barang_jadi
                }
              />
            </td>
            <td className="text-right">
              {DecimalConvert(parseFloat(values.qty ?? 0)).getWithComa}
            </td>
            <td className="text-right">
              {
                DecimalConvert(parseFloat(values.qty_sedang_diproduksi ?? 0))
                  .getWithComa
              }
            </td>
            <td className="text-right">
              {
                DecimalConvert(parseFloat(values.qty_direalisasi ?? 0))
                  .getWithComa
              }
            </td>
            <td>
              <InputQtyDecimal
                value={values.qty_adendum}
                onChange={(val) => setFieldValue("qty_adendum", val)}
              />
            </td>
            <td>{values.satuan ?? "-"}</td>
            <td>
              <NumberFormat
                value={values.unit_cost}
                onChange={(val) =>
                  setValues({
                    ...values,
                    unit_cost: val,
                    rounded: parseFloat(
                      (parseFloat(formik?.values?.profit ?? 0) *
                        parseFloat(values.unit_cost ?? 0)) /
                        100 +
                        val
                    ),
                  })
                }
              />
            </td>
            <td>
              {
                RupiahConvert(
                  String(
                    parseFloat(values.unit_cost ?? 0) +
                      (parseFloat(formik?.values?.profit ?? 0) *
                        parseFloat(values.unit_cost ?? 0)) /
                        100
                  )
                ).getWithComa
              }
            </td>
            <td>
              <NumberFormat
                value={values.rounded}
                onChange={(val) => setFieldValue("rounded", val)}
              />
            </td>
            <td>
              {
                RupiahConvert(
                  String(
                    parseFloat(values.qty_adendum ?? 0) *
                      parseFloat(values.rounded ?? 0)
                  )
                ).getWithComa
              }
            </td>
            <td className="text-center">
              {type === "update" ? (
                <ButtonGroup>
                  <ActionButton
                    size="sm"
                    variant="outline-success"
                    iconOnly
                    block
                    tooltip
                    tooltipText="Ubah Data"
                    onClick={handleSubmit}
                  >
                    <IoCheckmarkOutline size="14" />
                  </ActionButton>

                  <ActionButton
                    size="sm"
                    variant="outline-danger"
                    iconOnly
                    block
                    tooltip
                    tooltipText="Batal"
                    className="m-0"
                    onClick={() => setEditIndex(null)}
                  >
                    <IoCloseOutline size="14" />
                  </ActionButton>
                </ButtonGroup>
              ) : (
                <ActionButton
                  size="sm"
                  className="px-4"
                  type="submit"
                  onClick={handleSubmit}
                  text={<IoAdd />}
                />
              )}
            </td>
          </tr>
        );
      }}
    </Formik>
  );
};

const TableBarangJadiAdendum = ({ dataBarangJadi, setDataBarangJadi }) => {
  const {
    setIndexAnalisa,
    dataAnalisaBarangJadi,
    setDataAnalisaBarangJadi,
    type: contextType,
  } = useContext(SPKContext);

  const formik = useFormikContext();

  const [processedData, setProcessedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [editIndex, setEditIndex] = useState(null);

  const [dropdownBarangJadi, setDropdownBarangJadi] = useState([]);
  const [loadingDropdown, setLoadingDropdown] = useState(false);

  const getDropdownBarangJadi = () => {
    setLoadingDropdown(true);
    SuratPerjanjianKerjaApi.getBarangJadi()
      .then(({ data }) => {
        const mapingDataBarangJadi = (data) =>
          data.map((val) => ({
            label: val.nama_item,
            value: val.id_item_buaso,
            satuan: val.nama_satuan_jual,
            kode_item: val.kode_item,
          }));

        setDropdownBarangJadi(mapingDataBarangJadi(data.data ?? []));
      })
      .catch((err) => {})
      .finally(() => setLoadingDropdown(false));
  };

  const grandTotal = dataBarangJadi?.reduce(
    (acc, { rounded, qty_adendum, qty }) => {
      const checkQty =
        parseFloat(qty_adendum) > 0 ? parseFloat(qty_adendum) : qty;
      const subtotal = parseFloat(rounded ?? 0) * parseFloat(checkQty);
      return parseFloat(acc + subtotal);
    },
    0
  );

  const calculateDiskon = () => {
    const checkDiskon = formik.values.diskon
      ? parseFloat(formik.values.diskon)
      : 0;
    const totalDiskon = (checkDiskon * grandTotal) / 100;
    const totalSetelahDiskon = grandTotal - totalDiskon;

    return {
      totalDiskon: parseFloat(totalDiskon),
      totalSetelahDiskon: parseFloat(totalSetelahDiskon),
    };
  };

  const calculatePPN = () => {
    const totalSetelahDiskon = calculateDiskon().totalSetelahDiskon;
    const totalPPN =
      ((parseFloat(formik.values.ppn) || 0) * totalSetelahDiskon) / 100;
    const totalSetelahPPN = totalSetelahDiskon + totalPPN;

    return {
      totalPPN: parseFloat(totalPPN),
      totalSetelahPPN: parseFloat(totalSetelahPPN),
    };
  };

  const ModalSection = ({
    processedData,
    modalConfig,
    setModalConfig,
    dataBarangJadi,
    setDataBarangJadi,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiRAE
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={"xl"}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>

        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  useEffect(() => {
    getDropdownBarangJadi();
  }, []);

  console.log("yooo", dataBarangJadi);

  return loadingDropdown ? (
    <DataStatus text="Memuat Data..." loading />
  ) : (
    <>
      <div className="py-2 p-1">
        <b>List Barang Jadi</b>
      </div>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      <table
        className="table table-sm table-bordered table-responsive"
        style={{ fontSize: "14px" }}
      >
        <thead className="text-center bg-light">
          <tr>
            <th style={{ width: "100px" }}>Kode Barang Jadi</th>
            <th className="align-middle" style={{ width: "200px" }}>
              Barang Jadi
            </th>
            <th className="align-middle">Qty. SPK</th>
            <th className="align-middle">Qty. Sedang Diproduksi</th>
            <th className="align-middle">Qty. Telah Direalisasi</th>
            <th className="align-middle">Qty. Adendum SPK</th>
            <th className="align-middle">Satuan</th>
            <th className="align-middle">Harga Satuan</th>
            <th className="align-middle" style={{ width: "100px" }}>
              Profit (%)
              <InputQtyDecimal
                value={formik?.values?.profit ?? 0}
                onChange={(val) => formik.setFieldValue("profit", val)}
              />
            </th>
            <th className="align-middle">Rounded</th>
            <th className="align-middle">Subtotal</th>
            <th className="align-middle">Aksi</th>
          </tr>
        </thead>
        <tbody>
          <TableForm
            type="create"
            formik={formik}
            dataBarangJadi={dataBarangJadi}
            editIndex={editIndex}
            setDataBarangJadi={setDataBarangJadi}
            dropdownBarangJadi={dropdownBarangJadi}
            setEditIndex={setEditIndex}
          />
          {dataBarangJadi?.map((val, index) => {
            console.log("val", val);
            const checkQty =
              parseFloat(val.qty_adendum) > 0
                ? parseFloat(val.qty_adendum)
                : parseFloat(val.qty);
            const harga = val.rounded ? parseFloat(val?.rounded) : 0;
            const subTotal = parseFloat(checkQty * harga);

            return editIndex === index ? (
              <TableForm
                data={val}
                type="update"
                formik={formik}
                dataBarangJadi={dataBarangJadi}
                editIndex={editIndex}
                setDataBarangJadi={setDataBarangJadi}
                dropdownBarangJadi={dropdownBarangJadi}
                loadingDropdown={loadingDropdown}
                setEditIndex={setEditIndex}
              />
            ) : (
              <tr key={index}>
                <td>{val.kode_item}</td>
                <td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      // setIndexAnalisa(val.unique);
                      setProcessedData({
                        ...val,
                        index: index,
                      });
                      setModalConfig({
                        show: true,
                        type: "analisa",
                        title: (
                          <span className="text-primary">
                            Analisa Barang Jadi RAB
                          </span>
                        ),
                      });
                    }}
                  >
                    {val.nama_item}
                  </a>
                </td>
                <td className="text-right">
                  {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                </td>
                <td className="text-right">
                  {
                    DecimalConvert(parseFloat(val.qty_sedang_diproduksi ?? 0))
                      .getWithComa
                  }
                </td>
                <td className="text-right">
                  {
                    DecimalConvert(parseFloat(val.qty_direalisasi ?? 0))
                      .getWithComa
                  }
                </td>
                <td className="text-right">
                  {DecimalConvert(parseFloat(val.qty_adendum ?? 0)).getWithComa}
                </td>
                <td>{val.satuan ?? "-"}</td>
                <td className="text-right">
                  {
                    RupiahConvert(parseFloat(val.unit_cost ?? 0).toString())
                      .getWithComa
                  }
                </td>
                <td className="text-right">
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val.unit_cost ?? 0) +
                          (parseFloat(formik?.values?.profit ?? 0) *
                            parseFloat(val.unit_cost ?? 0)) /
                            100
                      )
                    ).getWithComa
                  }
                </td>
                <td className="text-right">
                  {
                    RupiahConvert(parseFloat(val.rounded ?? 0).toString())
                      .getWithComa
                  }
                </td>
                <td className="text-right">
                  {RupiahConvert(subTotal?.toString()).getWithComa}
                </td>
                <td>
                  <ButtonGroup>
                    <ActionButton
                      size="sm"
                      variant="success"
                      iconOnly
                      block
                      tooltip
                      tooltipText="Ubah Data"
                      onClick={() => setEditIndex(index)}
                    >
                      <IoPencilOutline size="14" />
                    </ActionButton>

                    <ActionButton
                      size="sm"
                      variant="danger"
                      iconOnly
                      block
                      tooltip
                      tooltipText="Hapus Data"
                      className="m-0"
                      onClick={() => {
                        const filterdData = dataBarangJadi.filter(
                          (res) => res.id_barang_jadi !== val.id_barang_jadi
                        );

                        setDataBarangJadi(filterdData);
                      }}
                    >
                      <IoTrashBinOutline size="14" />
                    </ActionButton>
                  </ButtonGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {/* Total */}
          <tr className="bg-light">
            <td colSpan={10} className="text-right py-2 align-middle">
              <b>Total</b>
            </td>
            <td className="text-right align-middle">
              <b>{RupiahConvert(String(grandTotal)).getWithComa}</b>
            </td>
            <Td></Td>
          </tr>

          {/* DISKON */}

          <tr>
            <td colSpan={10} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>Diskon (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  <InputQtyDecimal
                    size="sm"
                    value={parseFloat(formik?.values?.diskon ?? 0)}
                    // disabled
                    className="text-right"
                    onChange={(e) => {
                      formik.setFieldValue("diskon", e);
                      // onPPNChange(convert)
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculateDiskon().totalDiskon))
                    .getWithComa
                }
              </b>
            </td>
            <Td></Td>
          </tr>

          {/* TOTAL SETELAH DISKON */}
          <tr className="bg-light">
            <td colSpan={10} className="text-right py-2 align-middle">
              <b>Total Setelah Diskon</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculateDiskon().totalSetelahDiskon))
                    .getWithComa
                }
              </b>
            </td>
            <Td></Td>
          </tr>

          {/* PPN */}
          <tr>
            <td colSpan={10} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>PPN (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  <InputQtyDecimal
                    size="sm"
                    value={parseFloat(formik?.values?.ppn ?? 0)}
                    // disabled
                    className="text-right"
                    onChange={(e) => {
                      formik.setFieldValue("ppn", e);
                      // onPPNChange(convert)
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>
                {RupiahConvert(String(calculatePPN().totalPPN)).getWithComa}
              </b>
            </td>
            <Td></Td>
          </tr>

          {/* TOTAL SETELAH PPN */}
          <tr className="bg-light">
            <td colSpan={10} className="text-right py-2 align-middle">
              <b>Total Setelah Pajak</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculatePPN().totalSetelahPPN))
                    .getWithComa
                }
              </b>
            </td>
            <Td></Td>
          </tr>
        </tfoot>
        {/* <tfoot>
          <tr className="bg-light">
            <td colSpan={5} className="text-right py-2 align-middle">
              <b>Total</b>
            </td>
            <td className="text-right align-middle">
              <b>{RupiahConvert(String(grandTotal)).detail}</b>
            </td>
          </tr>
        </tfoot> */}
      </table>
      {modalConfig.show && (
        <ModalSection
          processedData={processedData}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
        />
      )}
    </>
  );
};

export default TableBarangJadiAdendum;
