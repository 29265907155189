import Services from "services";

class LaporanSPKApi {
  getPage(params) {
    return Services.get("/laporan_spk/page", { params });
  }

  getSPKExport(params) {
    return Services.get("/spk_report", { params });
  }

  getSegmentasiPasar() {
    return Services.get("/dropdown/segmentasi_pasar");
  }
}

export default new LaporanSPKApi();
