import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import {
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  Th,
  Td,
  TextEditor,
} from "components";
import {
  DateConvert,
  DecimalConvert,
  PageNumber as TableNumber,
} from "utilities";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

const ModalDetailRAE = ({ modalConfig, setModalConfig }) => {
  const { data } = modalConfig;
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });

  const ModalInfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-2">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    );

    return (
      <div className="mb-3">
        <Row>
          <Col lg={6}>
            <InfoItem
              title="Tanggal Peluang"
              value={
                data.tgl_peluang
                  ? DateConvert(new Date(data.tgl_peluang)).detail
                  : "-"
              }
            />
            <InfoItem title="No. Peluang" value={data?.no_peluang ?? "-"} />
            <InfoItem
              title="Keterangan Pengiriman"
              value={data?.keterangan_pengiriman ?? "-"}
            />
            <InfoItem
              title="Lokasi Pengiriman"
              value={data?.lokasi_pengiriman ?? "-"}
            />
          </Col>
          <Col lg={6}>
            <InfoItem
              title="Customer"
              value={data.nama_customer ? data.nama_customer : "-"}
            />
            <InfoItem title="ATT" value={data?.att ?? "-"} />
            <InfoItem
              title="Peringkat Peluang"
              value={data?.nama_peringkat_peluang ?? "-"}
            />
            <InfoItem title="Proyek" value={data?.nama_proyek ?? "-"} />
          </Col>
        </Row>
        <TextEditor
          readOnly
          options={[]}
          label="Informasi Lainya"
          editorState={textEditorState}
        />
      </div>
    );
  };

  const ModalTableSection = () => {
    return (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Kode Item</Th>
            <Th>Barang Jadi</Th>
            <Th>Qty</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.barang_jadi?.map((val, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>{val.kode_item}</Td>
              <Td>{val.nama_item}</Td>
              <Td>{DecimalConvert(val.qty_rab ?? 0).getWithComa}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );
  };

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data?.lain_lain ?? "<p></p>")
        )
      )
    );
  }, [data]);

  return (
    <Modal
      size="xl"
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {} })}
    >
      <Modal.Header closeButton className="py-2">
        <Modal.Title>
          <small className="text-primary">Detail Peluang</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalInfoSection />
        <ModalTableSection />
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailRAE;
