import Services from '../../services'

class AuthApi {
  login(value) {
    return Services.post('/auth/login', value)
  }

  notif(params) {
    return Services.get('/notifikasi/counter_notif', { params })
  }

  profile() {
    return Services.get("/auth/profile");
  }
}

export default new AuthApi()