import React, { useState, useEffect, useContext, memo } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, BackButton, DataStatus } from "components";
import { RABContextProvider, RABContext } from "../RABContext";
import { FormRAB, InfoSection, TableBarangJadi } from "../Section";
import { RABApi } from "api";

const DetailRAB = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_rab } = useParams();
  const { state } = useLocation();

  const ContentDetailRAE = memo(() => {
    const { setType, dataAnalisaBarangJadi, setDataAnalisaBarangJadi } =
      useContext(RABContext);
    const [dataRAB, setDataRAB] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      RABApi.getSingleRAB({ id_rab })
        .then((rab) => {
          const { profit } = rab?.data?.data;
          const mapDataBarangJadi = rab?.data?.data?.detail?.map((val) =>
            Object({
              ...val,
              qty: val.qty_rab,
              harga_satuan: val.unit_cost,
              profit: parseInt(val.profit),
              harga_profit:
                (parseInt(profit) / 100 + 1) * parseFloat(val.unit_cost),
              satuan: val.nama_satuan_beli,
              unique: Math.random().toString(36).substring(2, 9),
            })
          );

          setDataRAB(rab?.data?.data ?? {});
          setDataBarangJadi(mapDataBarangJadi ?? []);
        })
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_rab: id_rab,
      id_rae: dataRAB?.id_rae,
      tgl_rab: dataRAB?.tgl_rab ?? "",
      no_rab: dataRAB?.no_rab ?? "",
      profit: parseInt(dataRAB?.profit ?? 0),
    };
    const formValidationSchema = Yup.object().shape({
      tgl_rab: Yup.string().required("Pilih tanggal RAB"),
      no_rab: Yup.string().required("Pilih tanggal untuk menentukan nomor RAB"),
    });
    const formSubmitHandler = (values) => {
      const mapAnalisaBarangJadi = dataAnalisaBarangJadi?.map((item) => ({
        unique: item.unique,
        analisa: item?.analisa?.map((val) =>
          Object({
            id_item_buaso: val.id_item_buaso,
            id_item_atribut: val.id_item_atribut,
            id_buaso: val.id_buaso,
            qty: val.qty,
            harga_satuan: val.harga,
          })
        ),
      }));

      const mapBarangJadi = dataBarangJadi?.map((item) => ({
        id_barang_jadi: item.id_barang_jadi,
        qty_rae: item.qty,
        harga_satuan_rae: item.harga_satuan,
        analisa_barang_jadi:
          mapAnalisaBarangJadi?.find((val) => item.unique === val.unique)
            ?.analisa ?? [],
      }));

      const finalValues = {
        ...values,
        detail_barang_jadi: mapBarangJadi,
      };

      RABApi.save(finalValues)
        .then(() =>
          history.push("/transaksi/rae", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    console.log(dataBarangJadi);

    useEffect(() => {
      setNavbarTitle("Rencana Anggaran Biaya (RAB)");
      setType("DETAIL");
      getInitialData();
    }, []);

    const CatatanApproval = ({ data }) => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval RAB</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {data?.stakeholder?.map((val, index) => (
                <Col key={index} sm>
                  <InfoItem
                    title1={
                      val.status_approval === "VER" ||
                      val.status_approval === "REV"
                        ? `Pemeriksa ${
                            val.approval_level !== "0" ? val.approval_level : ""
                          }`
                        : val.status_approval === "APP"
                        ? "Pengesah"
                        : val.status_approval === "PEN"
                        ? "Pembuat"
                        : "Di Tolak Oleh"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data RAB</b>
          <BackButton
            onClick={() => history.push("/transaksi/rab", { ...state })}
          />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataRAB} />
              <hr />
              <FormRAB formik={formik} type="DETAIL" />
              <hr />
              <TableBarangJadi
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                profit={formik.values.profit}
                isDetail={true}
              />
              <CatatanApproval data={dataRAB} />
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <RABContextProvider>
      <ContentDetailRAE />
    </RABContextProvider>
  );
};

export default DetailRAB;
