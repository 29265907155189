import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  FilterButton,
  BackButton,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { RegistrasiPeluangApi } from "api";
import { IoAddOutline } from "react-icons/io5";

// import {
//   ModalStatusInfoPeluang,
//   StatusInfoPeluangButton,
//   ModalFilter,
// } from "./Comp";

const ListInfoPeluang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const state = location?.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    tgl_info_peluang_mulai: state?.dataFilter?.tgl_info_peluang_mulai,
    tgl_info_peluang_selesai: state?.dataFilter?.tgl_info_peluang_selesai,
    nama_marketing: state?.dataFilter?.nama_marketing,
    customer: state?.dataFilter?.customer,
    proyek: state?.dataFilter?.proyek,
    status_info_peluang: state?.dataFilter?.status_info_peluang,
    status_approval: state?.dataFilter?.status_approval,
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RegistrasiPeluangApi.getInfoPeluangPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tgl_info_peluang_mulai: dataFilter?.tgl_info_peluang_mulai,
      tgl_info_peluang_selesai: dataFilter?.tgl_info_peluang_selesai,
      nama_marketing: dataFilter?.nama_marketing,
      customer: dataFilter?.customer,
      proyek: dataFilter?.proyek,
      status_info_peluang: dataFilter?.status_info_peluang,
      status_approval: dataFilter?.status_approval,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.registerAlertConfig) {
        setAlertConfig({
          show: locationState.registerAlertConfig.show,
          text: locationState.registerAlertConfig.text,
          variant: locationState.registerAlertConfig.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Peluang");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter?.active,
    dataFilter?.tgl_info_peluang_mulai,
    dataFilter?.tgl_info_peluang_selesai,
    dataFilter?.nama_marketing,
    dataFilter?.customer,
    dataFilter?.proyek,
    dataFilter?.status_info_peluang,
    dataFilter?.status_approval,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Info Peluang Yang Siap Dibuatkan Peluang</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Info Peluang</ThFixed>
              <Th>Nama Marketing</Th>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>

                <TdFixed>
                  <div>
                    {val.tgl_info_peluang
                      ? DateConvert(new Date(val.tgl_info_peluang)).custom
                      : "-"}
                  </div>
                  <div>{val.no_info_peluang ?? "-"}</div>
                </TdFixed>
                <TdFixed className="text-nowrap">{val.nama_marketing}</TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_proyek ?? "-"}</Td>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    <ActionButton
                      size="sm"
                      variant="success"
                      className="my-1"
                      onClick={() =>
                        history.push(
                          `/transaksi/registrasi-peluang/list-info-peluang/tambah/${val.id_info_peluang}`
                        )
                      }>
                      <IoAddOutline />
                    </ActionButton>
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default ListInfoPeluang;
