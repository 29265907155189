import React, { useState, useEffect, useContext, memo } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Alert, BackButton, ActionButton, DataStatus } from "components";
import { RABContextProvider, RABContext } from "../RABContext";
import { FormRAB, InfoSection, TableBarangJadi } from "../Section";
import { RABApi } from "api";

const UbahRAB = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_rab } = useParams();
  const { state } = useLocation();

  const ContentUbahRAB = memo(() => {
    const { setType, dataAnalisaBarangJadi, setDataAnalisaBarangJadi } =
      useContext(RABContext);
    const [dataRAB, setDataRAB] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      RABApi.getSingleRAB({ id_rab })
        .then((rab) => {
          const { profit } = rab?.data?.data;
          const mapDataBarangJadi = rab?.data?.data?.detail?.map((val) => {
            return Object({
              ...val,
              qty: val.qty_rab,
              harga_satuan: val.unit_cost,
              profit: parseInt(val.profit),
              harga_profit:
                (parseInt(profit) / 100 + 1) * parseFloat(val.unit_cost),
              rounded: val.rounded ?? 0,
              satuan: val.nama_satuan_beli,
              unique: Math.random().toString(36).substring(2, 9),
            });
          });

          setDataRAB(rab?.data?.data ?? {});
          setDataBarangJadi(mapDataBarangJadi ?? []);
        })
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_rab: id_rab,
      id_rae: dataRAB?.id_rae,
      tgl_rab: dataRAB?.tgl_rab ?? "",
      no_rab: dataRAB?.no_rab ?? "",
      profit: parseInt(dataRAB?.profit ?? 0),
    };
    const formValidationSchema = Yup.object().shape({
      tgl_rab: Yup.string().required("Pilih tanggal RAB"),
      no_rab: Yup.string().required("Pilih tanggal untuk menentukan nomor RAB"),
    });

    const formSubmitHandler = (values) => {
      const mapAnalisaBarangJadi = dataAnalisaBarangJadi?.map((item) => ({
        unique: item.unique,
        analisa: item?.analisa?.map((val) =>
          Object({
            id_item_buaso: val.id_item_buaso,
            id_item_atribut: val.id_item_atribut,
            id_buaso: val.id_buaso,
            qty: val.qty,
            harga_satuan: val.harga,
          })
        ),
      }));

      const mapBarangJadi = dataBarangJadi?.map((item) => ({
        id_rab_detail: item.id_rab_detail,
        id_rae_detail: item.id_rae_detail,
        id_item_atribut: item.id_item_atribut,
        qty_rab: item.qty,
        unit_cost: item.harga_satuan ?? 0,
        rounded: item.rounded ?? 0,
        analisa_barang_jadi:
          mapAnalisaBarangJadi?.find((val) => item.unique === val.unique)
            ?.analisa ?? [],
      }));

      const finalValues = {
        ...values,
        detail_barang_jadi: mapBarangJadi,
      };

      RABApi.update(finalValues)
        .then(() =>
          history.push("/transaksi/rab", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const onProfitChange = (profit) => {
      const newProfit = profit ? profit : 0;

      const newList = dataBarangJadi?.map((val) => {
        const afterProfit = (newProfit / 100 + 1) * val.harga_satuan;
        return {
          ...val,
          harga_profit: parseInt(afterProfit),
          rounded: parseInt(afterProfit),
        };
      });

      formik.setFieldValue("profit", profit);
      setDataBarangJadi(newList);
    };

    const onInputRoundedChange = ({ value, index }) => {
      const newList = dataBarangJadi.map((val, idx) =>
        idx === index ? { ...val, rounded: value } : val
      );

      setDataBarangJadi(newList);
    };

    useEffect(() => {
      setNavbarTitle("Rencana Anggaran Biaya (RAB)");
      setType("DETAIL");
      getInitialData();
    }, []);

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Ubah Data RAB</b>
          <BackButton
            onClick={() => history.push("/transaksi/rab", { ...state })}
          />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataRAB} />
              <hr />
              <FormRAB formik={formik} type="UPDATE" />
              <hr />
              <TableBarangJadi
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                profit={formik.values.profit}
                onProfitChange={onProfitChange}
                onInputRoundedChange={onInputRoundedChange}
              />
              <div className="mt-4 d-flex justify-content-end align-items-center">
                <ActionButton
                  variant="success"
                  text="Ubah Rencana Anggaran Biaya"
                  loading={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <RABContextProvider>
      <ContentUbahRAB />
    </RABContextProvider>
  );
};

export default UbahRAB;
