// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Bootstrap
import { Card, Col, Row } from "react-bootstrap";

// Form
import { Formik } from "formik";

// API
import axios from "axios";
import { InfoPeluangApi } from "api";

// Component
import {
  CRUDLayout,
  Input,
  DataStatus,
  Alert,
  BackButton,
  ActionButton,
  SelectSearch,
  DatePicker,
  TextEditor,
} from "components";

// View
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  formInitialValues,
  formSubmitHandler,
  formValidationSchema,
} from "./Utils";
import { DateConvert } from "utilities";

const TambahInfoPeluang = ({ setNavbarTitle }) => {
  const title = "Info Peluang";

  // USE HISTORY
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const isUpdateform = state.aksi === "UPDATE" ? true : false;

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // DATA
  const [dataNoBaru, setDataNoBaru] = useState("");
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataProyek, setDataProyek] = useState([]);
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const [data, setData] = useState({});
  const textEditorData = "";

  const [textEditorState, setTextEditorState] = useState(() => {
    return isUpdateform === true
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(textEditorData))
        )
      : EditorState.createEmpty();
  });

  const updateEditorState = (value, setFieldValue) => {
    setTextEditorState(value);

    const convertData = convertToRaw(value.getCurrentContent());

    setFieldValue("keterangan", draftToHtml(convertData));
  };

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const getData = () => {
    setIsLoading(true);

    axios
      .all([
        InfoPeluangApi.getDropdownCustomer(),
        InfoPeluangApi.getDropdownProyek(),
        InfoPeluangApi.getDropdownKaryawan(),
      ])
      .then(
        axios.spread((customer, proyek, karyawan) => {
          setDataCustomer(customer.data.data);
          setDataProyek(proyek.data.data);
          setDataKaryawan(karyawan.data.data);
        })
      )
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: `Tidak dapat memuat data!`,
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getDetailUpdate = () => {
    InfoPeluangApi.getSingle(id)
      .then((res) => {
        setData(res.data.data);

        setTextEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(res?.data?.data?.keterangan ?? "<p></p>")
            )
          )
        );
      })
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: `Tidak dapat memuat data!`,
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getNoBaru = (data) => {
    InfoPeluangApi.getNoBaru(data)
      .then((res) => setDataNoBaru(res?.data?.data))
      .catch((err) => alert("gagal memuat nomor baru!"))
      .finally(() => setIsLoading(false));
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setIsLoading(false);
    setNavbarTitle(isUpdateform ? "Ubah " + title : "Tambah " + title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
    isUpdateform && getDetailUpdate(); //Get Data Single Untuk Update
    getNoBaru(DateConvert(new Date()).default);
  }, []);

  // KIRIM UPDATE DATA KE SERVER
  const submitHandler = (values) => {
    const savingValue = formSubmitHandler(values, state.aksi);
    const fetchData = isUpdateform
      ? InfoPeluangApi.update(savingValue)
      : InfoPeluangApi.create(savingValue);

    fetchData
      .then(() => {
        history.push("/transaksi/info-peluang", {
          registerAlertConfig: {
            variant: isUpdateform ? "success" : "primary",
            text: `${isUpdateform ? "Ubah" : "Tambah"} data berhasil!`,
            show: true,
          },
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: "danger",
          text: `${isUpdateform ? "Ubah" : "Tambah"} data gagal! (${
            err.response.data.message
          })`,
        });
      })
      .finally(() => {
        // SHOW ALERT
        setShowAlert(true);
      });
  };

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      <div className="d-flex justify-content-between">
        <b>
          {isUpdateform ? "Ubah" : "Tambah"} Data {title}
        </b>
        <BackButton size="sm" onClick={() => history.goBack()} />
      </div>
      <Card className="pb-3 mt-2">
        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : (
          <Formik
            initialValues={formInitialValues(data)}
            validationSchema={formValidationSchema}
            onSubmit={submitHandler}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Card.Body>
                    <Row>
                      <Col lg>
                        <Row>
                          <Col lg="6">
                            <DatePicker
                              label="Tanggal Peluang"
                              name="tgl_info_peluang"
                              selected={
                                values.tgl_info_peluang
                                  ? new Date(values.tgl_info_peluang)
                                  : ""
                              }
                              onChange={(val) => {
                                const value = val.toISOString().slice(0, 10);
                                setFieldValue("tgl_info_peluang", value);
                                getNoBaru(value);
                              }}
                              error={
                                errors.tgl_info_peluang &&
                                touched.tgl_info_peluang &&
                                true
                              }
                              errorText={errors.tgl_info_peluang}
                            />
                          </Col>
                          <Col lg="6">
                            <Input
                              label="No Peluang"
                              type="text"
                              name="no_info_peluang"
                              value={dataNoBaru}
                              readOnly={true}
                            />
                          </Col>
                          <Col lg="6">
                            <SelectSearch
                              label="Customer"
                              name="id_customer"
                              key={values.id_customer}
                              onChange={(val) => {
                                setFieldValue("id_customer", val.value);
                                setFieldValue("nama_customer", val.label);
                              }}
                              option={dataCustomer.map((val) => {
                                return {
                                  value: val.id_customer,
                                  label: val.nama_customer,
                                };
                              })}
                              defaultValue={{
                                value: values.id_customer
                                  ? values.id_customer
                                  : "",
                                label: values.id_customer
                                  ? values.nama_customer
                                  : "Pilih Customer",
                              }}
                              error={
                                errors.id_customer &&
                                touched.id_customer &&
                                true
                              }
                              errorText={
                                errors.id_customer &&
                                touched.id_customer &&
                                errors.id_customer
                              }
                            />
                          </Col>
                          <Col lg="6">
                            <SelectSearch
                              label="Proyek"
                              name="id_proyek"
                              key={values.id_proyek}
                              onChange={(val) => {
                                setFieldValue("id_proyek", val.value);
                                setFieldValue("nama_proyek", val.label);
                              }}
                              option={dataProyek.map((val) =>
                                Object({
                                  value: val.id_proyek,
                                  label: val.nama_proyek,
                                })
                              )}
                              defaultValue={{
                                value: values.id_proyek ? values.id_proyek : "",
                                label: values.nama_proyek
                                  ? values.nama_proyek
                                  : "Pilih Proyek",
                              }}
                              error={
                                errors.id_proyek && touched.id_proyek && true
                              }
                              errorText={
                                errors.id_proyek &&
                                touched.id_proyek &&
                                errors.id_proyek
                              }
                            />
                          </Col>
                          <Col lg>
                            <SelectSearch
                              key={values.id_nama_marketing}
                              label="Nama Marketing"
                              name="id_nama_marketing"
                              onChange={(val) => {
                                setFieldValue("id_nama_marketing", val.value);
                                setFieldValue("nama_marketing", val.label);
                              }}
                              option={dataKaryawan.map((val) =>
                                Object({
                                  value: val.id_karyawan,
                                  label: val.nama_karyawan,
                                })
                              )}
                              defaultValue={{
                                value: values.id_nama_marketing
                                  ? values.id_nama_marketing
                                  : "",
                                label: values.nama_marketing
                                  ? values.nama_marketing
                                  : "Pilih nama marketing",
                              }}
                              error={
                                errors.id_nama_marketing &&
                                touched.id_nama_marketing &&
                                true
                              }
                              errorText={
                                errors.id_nama_marketing &&
                                touched.id_nama_marketing &&
                                errors.id_nama_marketing
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col lg>
                        <TextEditor
                          key={values.keterangan}
                          label="Keterangan"
                          placeholder="Masukan keterangan"
                          editorState={textEditorState}
                          editorStyle={{ height: 115 }}
                          onEditorStateChange={(val) => {
                            updateEditorState(val, setFieldValue, "keterangan");
                          }}
                          error={
                            errors.keterangan && touched.keterangan && true
                          }
                          errorText={
                            errors.keterangan &&
                            touched.keterangan &&
                            errors.keterangan
                          }
                        />
                      </Col>
                    </Row>
                    <div className="text-right mt-2">
                      <ActionButton
                        type="submit"
                        variant={isUpdateform ? "success" : "primary"}
                        className="btn btn-primary text-white"
                        size="md"
                        text={isUpdateform ? "Ubah" : "Simpan"}
                        loading={isSubmitting}
                      />
                    </div>
                  </Card.Body>
                </form>
              </>
            )}
          </Formik>
        )}
      </Card>
    </CRUDLayout>
  );
};
export default TambahInfoPeluang;
