import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { InfoPeluangApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataKaryawan, setDataKaryawan] = useState([
    { label: "Semua", value: undefined },
  ]);

  const [dataCustomer, setDataCustomer] = useState([
    { label: "Semua", value: undefined },
  ]);

  const [dataProyek, setDataProyek] = useState([
    { label: "Semua", value: undefined },
  ]);

  const [dataStatusInfoPeluang, setDataStatusInfoPeluang] = useState([
    { label: "Semua", value: undefined },
    { label: "INFO", value: "1" },
    { label: "PERSPEKTIF", value: "2" },
    { label: "TARGET", value: "3" },
  ]);

  const [dataStatusApproval, setDataStatusApproval] = useState([
    {
      label: "Semua",
      value: undefined,
    },
    {
      label: "APPROVED",
      value: "APP",
    },
    {
      label: "VERIFIED",
      value: "VER",
    },
    {
      label: "REVISI",
      value: "REV",
    },
    {
      label: "REJECT",
      value: "REJ",
    },
    {
      label: "PENDING",
      value: "PEN",
    },
  ]);
  const [loading, setLoading] = useState({
    allDropdown: true,
    proyek: true,
  });
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      InfoPeluangApi.getDropdownKaryawan(),
      InfoPeluangApi.getDropdownCustomer(),
      InfoPeluangApi.getDropdownProyek(),
    ])
      .then(
        Axios.spread((karyawan, customer, proyek) => {
          const mapDataKaryawan = karyawan?.data?.data
            ? karyawan.data.data.map((val) => ({
                label: val?.nama_karyawan,
                value: val?.id_karyawan,
              }))
            : [];
          const mapDataCustomer = customer?.data?.data
            ? customer.data.data.map((val) => ({
                label: val?.nama_customer,
                value: val?.id_customer,
              }))
            : [];
          const mapDataProyek = proyek?.data?.data
            ? proyek.data.data.map((val) => ({
                label: val?.nama_proyek,
                value: val?.id_proyek,
              }))
            : [];

          setDataKaryawan([...dataKaryawan, ...mapDataKaryawan]);
          setDataCustomer([...dataCustomer, ...mapDataCustomer]);
          setDataProyek([
            { label: "Semua", value: undefined },
            ...mapDataProyek,
          ]);
        })
      )
      .finally(() => {
        setLoading({
          ...loading,
          allDropdown: false,
          proyek: false,
        });
      });
  };
  // DROPDOWN PROYEK
  const getProyek = (data) => {
    setLoading({
      ...loading,
      proyek: true,
    });

    InfoPeluangApi.getDropdownProyek({ id_customer: data })
      .then((proyek) => {
        const mapDataProyek = proyek?.data?.data
          ? proyek.data.data.map((val) => ({
              label: val.nama_proyek,
              value: val.id_proyek,
            }))
          : [];
        setDataProyek([{ label: "Semua", value: undefined }, ...mapDataProyek]);
      })
      .finally(() => {
        setLoading({
          ...loading,
          proyek: false,
        });
      });
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // USE EFFECT DROPDOWN PROYEK
  useEffect(() => {
    data.customer && getProyek(data.customer);
  }, [data.customer]);
  // FORM VALUES
  const formInitialValues = {
    tgl_info_peluang_mulai: data?.tgl_info_peluang_mulai,
    tgl_info_peluang_selesai: data?.tgl_info_peluang_selesai,
    nama_marketing: data?.nama_marketing,
    customer: data?.customer,
    proyek: data?.proyek,
    status_info_peluang: data?.status_info_peluang,
    status_approval: data?.status_approval,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({ ...values, active: true });
    } else {
      setData({ ...values, active: false });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalPeluangChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_info_peluang_mulai: startDate,
      tgl_info_peluang_selesai: endDate,
    });
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_info_peluang_mulai: undefined,
      tgl_info_peluang_selesai: undefined,
      // peringkat_peluang: undefined,
      customer: undefined,
      proyek: undefined,
      unit_produksil: undefined,
      status_approval: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}>
          <DatePicker
            selectsRange
            label="Tgl. Info Peluang"
            placeholderText="Pilih tanggal info peluang"
            startDate={
              values.tgl_info_peluang_mulai
                ? new Date(values.tgl_info_peluang_mulai)
                : ""
            }
            endDate={
              values.tgl_info_peluang_selesai
                ? new Date(values.tgl_info_peluang_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalPeluangChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.nama_marketing}
            label="Nama Marketing"
            placeholder="Pilih Nama Marketing"
            defaultValue={dataKaryawan.find(
              (val) => val.value === values.nama_marketing
            )}
            option={dataKaryawan}
            onChange={(val) => setFieldValue("nama_marketing", val.value)}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dataCustomer.find(
              (val) => val.value === values.customer
            )}
            option={dataCustomer}
            onChange={(val) => {
              setValues({
                ...values,
                customer: val.value,
                proyek: undefined,
              });
              getProyek(val.value, values, setValues);
            }}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.proyek}
            label="Proyek"
            placeholder="Pilih proyek untuk memilih proyek"
            defaultValue={dataProyek.find((val) => val.value === values.proyek)}
            option={dataProyek}
            onChange={(val) => setFieldValue("proyek", val.value)}
            loading={loading.proyek}
          />
          <SelectSearch
            key={values.status_info_peluang}
            label="Status Info Peluang"
            placeholder="Pilih Status Info Peluang"
            defaultValue={dataStatusInfoPeluang.find(
              (val) => val.value === values.status_info_peluang
            )}
            option={dataStatusInfoPeluang}
            onChange={(val) => setFieldValue("status_info_peluang", val.value)}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.status_approval}
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={dataStatusApproval.find(
              (val) => val.value === values.status_approval
            )}
            option={dataStatusApproval}
            onChange={(val) => setFieldValue("status_approval", val.value)}
            loading={loading.allDropdown}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
