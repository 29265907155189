// React
import React, { useState, useEffect, useContext, memo, useRef } from "react";

// Router
import { useHistory, useParams, useLocation } from "react-router-dom";

// Components
import { Row, Col, Card } from "react-bootstrap";
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  TextEditor,
} from "components";

// Form
import { useFormik } from "formik";

// Draft JS
import { EditorState, ContentState, convertFromHTML } from "draft-js";

// View Components
import { SPKContextProvider, SPKContext } from "../SPKContext";
import { FormSPK, InfoSection, TableBarangJadi } from "../Section";

// API
import { SuratPerjanjianKerjaApi } from "api";

// React to Print
import ReactToPrint from "react-to-print";
import PrintSPK from "./PrintSPK";

const DetailSPK = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const { id_spk } = useParams();
  const { state } = useLocation();
  const componentRef = useRef();

  const ContentDetailSPK = memo(() => {
    // Hooks
    const { setType } = useContext(SPKContext);

    // States
    const [dataSPK, setDataSPK] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });
    const [textEditorStateCatatan, setTextEditorStateCatatan] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`<p>Catatan :<br />`))
      );
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      SuratPerjanjianKerjaApi.getSingle({ id_spk })
        .then((spk) => {
          const mapDataBarangJadi = spk?.data?.data?.detail_barang_jadi?.map(
            (val) =>
              Object({
                ...val,
                satuan: val.satuan,
                qty: val.qty ?? 0,
                harga_satuan: val.unit_cost ?? 0,
                rounded: val.rounded,
              })
          );

          setDataSPK(spk?.data?.data ?? {});
          setDataBarangJadi(mapDataBarangJadi ?? []);
        })
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_spk: id_spk,
      id_penawaran: dataSPK?.id_penawaran ?? "",
      tgl_spk: dataSPK?.tgl_spk ?? "",
      no_spk: dataSPK?.no_spk ?? "",
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
    });

    useEffect(() => {
      setNavbarTitle("Surat Perjanjian Kerja");
      setType("DETAIL");
      getInitialData();

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setTextEditorStateCatatan(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(dataSPK?.catatan ?? "<p></p>")
          )
        )
      );
      return () => {};
    }, [dataSPK]);

    const CatatanApproval = ({ data }) => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval SPK</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {data?.stakeholder?.map((val, index) => (
                <Col key={index} sm>
                  <InfoItem
                    title1={
                      val.status_approval === "VER" ||
                      val.status_approval === "REV"
                        ? `Pemeriksa ${
                            val.approval_level !== "0" ? val.approval_level : ""
                          }`
                        : val.status_approval === "APP"
                        ? "Pengesah"
                        : val.status_approval === "PEN"
                        ? "Pembuat"
                        : "Di Tolak Oleh"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data SPK</b>

          <div>
            {!isPageLoading && (
              <ReactToPrint
                trigger={() => (
                  <ActionButton className="mb-1 mx-2" text="Cetak" />
                )}
                content={() => componentRef.current}
              />
            )}
            <BackButton
              onClick={() => history.push("/transaksi/spk", { ...state })}
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataSPK} />
              <hr />
              <FormSPK formik={formik} type="DETAIL" />
              <TableBarangJadi
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                ppn={dataSPK.ppn}
                diskon={dataSPK.diskon}
              />
              <TextEditor
                readOnly
                label="Catatan"
                placeholder="Tuliskan teks catatan"
                editorState={textEditorStateCatatan}
              />
              <CatatanApproval data={dataSPK} />

              <div style={{ display: "none" }}>
                <PrintSPK
                  ref={componentRef}
                  data={dataSPK}
                  dataBarangJadi={dataBarangJadi}
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <SPKContextProvider>
      <ContentDetailSPK />
    </SPKContextProvider>
  );
};

export default DetailSPK;
