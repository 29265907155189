import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, SelectSearch } from "components";
import Axios from "axios";
import { AnalisaBarangJadiMarketingApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataJenisBarang, setDataJenisBarang] = useState([
    { label: "Semua", value: undefined },
  ]);

  const [dataStatusApproval, setDataStatusApproval] = useState([
    {
      label: "Semua",
      value: undefined,
    },
    {
      label: "APPROVED",
      value: "APP",
    },
    {
      label: "VERIFIED",
      value: "VER",
    },
    {
      label: "REVISI",
      value: "REV",
    },
    {
      label: "REJECT",
      value: "REJ",
    },
    {
      label: "PENDING",
      value: "PEN",
    },
  ]);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([AnalisaBarangJadiMarketingApi.getDropdown({ tipe: "jenis" })])
      .then(
        Axios.spread((jenis) => {
          const mapDataKaryawan = jenis?.data?.data
            ? jenis.data.data.map((val) => ({
                label: val?.nama_jenis,
                value: val?.id_jenis,
              }))
            : [];

          setDataJenisBarang([...dataJenisBarang, ...mapDataKaryawan]);
        })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // USE EFFECT DROPDOWN PROYEK

  // FORM VALUES
  const formInitialValues = {
    jenis_barang: data?.jenis_barang,
    status_approval: data?.status_approval,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({ ...values, active: true });
    } else {
      setData({ ...values, active: false });
    }

    setShow(false);
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      jenis_barang: undefined,
      status_approval: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            key={values.jenis_barang}
            label="Jenis Barang"
            placeholder="Pilih jenis barang"
            defaultValue={dataJenisBarang.find(
              (val) => val.value === values.jenis_barang
            )}
            option={dataJenisBarang}
            onChange={(val) => setFieldValue("jenis_barang", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.status_approval}
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={dataStatusApproval.find(
              (val) => val.value === values.status_approval
            )}
            option={dataStatusApproval}
            onChange={(val) => setFieldValue("status_approval", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
