import React from "react";

export const PeringkatPeluang = ({ date, title, loading, value }) => {
  return (
    <div className="bg-white rounded border shadow-sm p-2">
      {loading ? (
        <small>Memuat data . . .</small>
      ) : (
        <>
          <b style={{ fontSize: 15 }}>{title}</b>
          <br />
          <b style={{ fontSize: 12 }}>{date}</b>
          <br />
          <div className="mt-4">
            <b style={{ fontSize: 25, fontWeight: 1200, lineHeight: 1.2 }}>
              {value}
            </b>
          </div>
        </>
      )}
    </div>
  );
};
