import React from "react";

export const InfoPeluang = ({ title, loading, value, date, type }) => {
  return (
    <div className="bg-white rounded border shadow-sm p-2">
      {loading ? (
        <small>Memuat data . . .</small>
      ) : (
        <>
          <b style={{ fontSize: 15 }}>{title}</b>
          <br />
          <b style={{ fontSize: 12 }}>{date}</b>
          <br />
          <div className="d-flex justify-content-between align-items-end mt-2">
            <div
              className={`${
                type == "info"
                  ? "bg-warning"
                  : type == "perspektif"
                  ? "bg-success"
                  : "bg-primary"
              } btn-sm text-white px-2 mt-3 text-uppercase`}
            >
              {type == "info"
                ? "Info"
                : type == "perspektif"
                ? "Perspektif"
                : "Target"}
            </div>

            <div>
              <b style={{ fontSize: 25, fontWeight: 1200, lineHeight: 1.2 }}>
                {value}
              </b>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
