import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const TextEditor = ({ label, placeholder, error, errorText, editorState, onEditorStateChange, readOnly, options, editorStyle }) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className="mb-2">
      <label>
        <small>
          {label}
        </small>
      </label>
      <Editor
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        wrapperClassName={`text-editor ${isFocused && 'focused'} ${error && 'is-invalid'}`}
        editorClassName="px-3"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        readOnly={readOnly}
        editorStyle={editorStyle}
        toolbar={{
          options: options ?? ['inline', 'list', 'textAlign'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          }
        }}
      />
      <small className="mt-1 text-danger">
        {errorText}
      </small>
    </div>
  )
}

export default TextEditor
