import React, { useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { DateConvert } from "utilities";
import { ModalPeluang } from ".";

const InfoSection = ({ data, action }) => {
  const [modalPeluangConfig, setModalPeluangConfig] = useState({
    show: false,
    data_peluang: data,
  });

  const InfoItem = ({ title, value, link, onClick }) => (
    <tr onClick={onClick}>
      <td width={110}>{title}</td>
      <td className={`pl-4 pr-2`}>:</td>
      <td
        className={link ? "text-primary" : ""}
        style={{ cursor: link ? "pointer" : "default" }}
      >
        {value}
      </td>
    </tr>
  );

  return (
    <>
      <Row>
        <Col>
          <table style={{ fontSize: "13px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Peluang"
                value={
                  data.tgl_peluang
                    ? DateConvert(new Date(data.tgl_peluang)).detail
                    : "-"
                }
              />
              <InfoItem
                link
                title="No. Peluang"
                value={data?.no_peluang ?? "-"}
                onClick={() =>
                  setModalPeluangConfig({
                    show: true,
                    data_peluang: data,
                  })
                }
              />
              <InfoItem
                title="Peringkat Peluang"
                value={data?.nama_peringkat_peluang ?? "-"}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "13px" }}>
            <tbody>
              <InfoItem title="Customer" value={data?.nama_customer ?? "-"} />
              <InfoItem title="ATT" value={data?.att ?? "-"} />
              {/* <InfoItem title="Tgl.Selesai" value={data.tgl_selesai ? DateConvert(new Date(data.tgl_selesai)).detail : "-"} /> */}
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "13px" }}>
            <tbody>
              <InfoItem title="No. Telepon" value={data?.telepon ?? "-"} />
              <InfoItem
                title="Alamat Pengiriman"
                value={data?.lokasi_pengiriman ?? "-"}
              />
              <InfoItem title="Proyek" value={data.nama_proyek ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>
      <hr />
      <Row>
        {/* <Col>
          <table style={{ fontSize: "13px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Unit Cost"
                value={data.tgl_rae ? DateConvert(new Date(data.tgl_rae)).detail : "-"} />
              <InfoItem
                title="No. Unit Cost"
                value={data?.no_rae ?? "-"} />
            </tbody>
          </table>
        </Col> */}
        <Col>
          <table style={{ fontSize: "13px" }}>
            <tbody>
              <InfoItem
                title="Tgl. RAB"
                value={
                  data.tgl_rab
                    ? DateConvert(new Date(data.tgl_rab)).detail
                    : "-"
                }
              />
              <InfoItem title="No. RAB" value={data?.no_rab ?? "-"} />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "13px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  data.tgl_penawaran
                    ? DateConvert(new Date(data.tgl_penawaran)).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Penawaran"
                value={data?.no_penawaran ?? "-"}
              />
            </tbody>
          </table>
        </Col>
        {action !== "adendum" && (
          <Col>
            <table style={{ fontSize: "13px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. Adendum SPK"
                  value={
                    data.telah_diadendum
                      ? DateConvert(new Date(data.tgl_spk_adendum)).detail
                      : "Belum ada adendum"
                  }
                />
                <InfoItem
                  title="No. Adendum SPK"
                  value={
                    data.telah_diadendum
                      ? data?.no_spk_adendum
                      : "Belum ada adendum"
                  }
                />
              </tbody>
            </table>
          </Col>
        )}
        {action === "adendum" && (
          <Col>
            <table style={{ fontSize: "13px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. SPK"
                  value={DateConvert(new Date(data.tgl_spk)).detail}
                />
                <InfoItem title="No. SPK" value={data?.no_spk} />
              </tbody>
            </table>
          </Col>
        )}
      </Row>
      {modalPeluangConfig.show && (
        <ModalPeluang
          modalPeluangConfig={modalPeluangConfig}
          setModalPeluangConfig={setModalPeluangConfig}
        />
      )}
    </>
  );
};

export default InfoSection;
