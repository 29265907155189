import React, { useState, useEffect, useContext, memo } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Alert, BackButton, DataStatus } from "components";
import { RAEContextProvider, RAEContext } from "../RAEContext";
import { FormRAE, InfoSection, TableBarangJadi } from "../Section";
import { RAEApi } from "api";

const DetailRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_rae } = useParams();
  const { state } = useLocation();

  const ContentDetailRAE = memo(() => {
    const { setType, setActionBOK, dataAnalisaBarangJadi, setDataAnalisaBarangJadi } =
      useContext(RAEContext);
    const [dataRAE, setDataRAE] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [dataSelectBarangJadi, setDataSelectBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const getInitialData = () => {
      setIsPageLoading(true);
      Axios.all([RAEApi.getSingleRAE({ id_rae }), RAEApi.getDataSelectBarangJadi()])
        .then(
          Axios.spread((rae, selectBarangJadi) => {
            const mapSelectBarangJadi = selectBarangJadi?.data?.data?.map((val) =>
              Object({
                ...val,
                value: val.id_item_buaso,
                label: `${val.kode_item} - ${val.nama_item}`,
                nama_satuan: val.nama_satuan_jual,
              })
            );

            const mapDataBarangJadi = rae?.data?.data?.detail?.map((val) =>
              Object({
                id_rae_detail: val.id_rae_detail,
                id_rae: val.id_rae,
                id_barang_jadi: val.id_barang_jadi,
                qty: val.qty_rae,
                harga_satuan: val.harga_satuan_rae,
                kode_item: val.kode_item,
                nama_item: val.nama_item,
                satuan: val.nama_satuan_jual,
                unique: Math.random().toString(36).substring(2, 9),
              })
            );

            setDataRAE(rae?.data?.data ?? {});
            setDataSelectBarangJadi(mapSelectBarangJadi ?? []);
            setDataBarangJadi(mapDataBarangJadi ?? []);
          })
        )
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_rae: id_rae,
      tgl_rae: dataRAE?.tgl_rae ?? "",
      no_rae: dataRAE?.no_rae ?? "",
      baseline: dataRAE?.baseline ?? "1",
    };
    const formValidationSchema = Yup.object().shape({
      tgl_rae: Yup.string().required("Pilih tanggal RAE"),
      no_rae: Yup.string().required("Pilih tanggal untuk menentukan nomor RAE"),
    });
    const formSubmitHandler = (values) => {
      const mapAnalisaBarangJadi = dataAnalisaBarangJadi?.map((item) => ({
        unique: item.unique,
        analisa: item?.analisa?.map((val) =>
          Object({
            id_item_buaso: val.id_item_buaso,
            id_item_atribut: val.id_item_atribut,
            id_buaso: val.id_buaso,
            qty: val.qty,
            harga_satuan: val.harga,
          })
        ),
      }));

      const mapBarangJadi = dataBarangJadi?.map((item) => ({
        id_barang_jadi: item.id_barang_jadi,
        qty_rae: item.qty,
        harga_satuan_rae: item.harga_satuan,
        analisa_barang_jadi:
          mapAnalisaBarangJadi?.find((val) => item.unique === val.unique)?.analisa ?? [],
      }));

      const finalValues = {
        ...values,
        detail_barang_jadi: mapBarangJadi,
      };

      RAEApi.save(finalValues)
        .then(() =>
          history.push("/transaksi/rae", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    useEffect(() => {
      setNavbarTitle("Rencana Anggaran Estimasi (RAE)");
      setType("DETAIL");
      setActionBOK("DETAIL");
      getInitialData();
    }, []);

    const CatatanApproval = ({ data }) => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval RAE</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {data?.stakeholder?.map((val, index) => (
                <Col key={index} sm>
                  <InfoItem
                    title1={
                      val.status_approval === "VER" || val.status_approval === "REV"
                        ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                        : val.status_approval === "APP"
                        ? "Pengesah"
                        : val.status_approval === "PEN"
                        ? "Pembuat"
                        : "Di Tolak Oleh"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data RAE</b>
          <BackButton onClick={() => history.push("/transaksi/rae", { ...state })} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataRAE} />
              <hr />
              <FormRAE formik={formik} type="DETAIL" />
              <hr />
              <TableBarangJadi
                // dataSelectBarangJadi={dataSelectBarangJadi}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
              />
              <CatatanApproval data={dataRAE} />
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <RAEContextProvider>
      <ContentDetailRAE />
    </RAEContextProvider>
  );
};

export default DetailRAE;
