import React, { useState, useEffect, useContext } from 'react'
import { Card, Nav } from 'react-bootstrap'
import TableAnalisa from './table/TableAnalisa'
import TableSummary from './table/TableSummary'
import { ActionButton } from 'components'
import { PenawaranContext } from '../../PenawaranContext'

const TableSection = ({ data, dataSelect, dataBarangJadi, setDataBarangJadi, modalConfig, setModalConfig }) => {
  const { type: contextType, indexAnalisa, dataAnalisaBarangJadi, setDataAnalisaBarangJadi } = useContext(PenawaranContext)
  const [dataAnalisa, setDataAnalisa] = useState([])
  const [tableTabs, setTableTabs] = useState('bahan')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const dataSelectBahan = dataSelect?.filter(item => item.id_buaso === '1') ?? []
  const dataSelectUpah = dataSelect?.filter(item => item.id_buaso === '2') ?? []
  const dataSelectAlatMesin = dataSelect?.filter(item => item.id_buaso === '3') ?? []
  const dataSelectSubkon = dataSelect?.filter(item => item.id_buaso === '4') ?? []
  const dataSelectOverhead = dataSelect?.filter(item => item.id_buaso === '5' && item.id_kelompok !== '13') ?? []
  const dataSelectBOK = dataSelect?.filter(item => item.id_buaso === '5' && item.id_kelompok === '13') ?? []

  const isAnalisaExistinContext = (indexAnalisa) => {
    const findData = dataAnalisaBarangJadi?.find(item => item.unique === indexAnalisa)
    Boolean(findData) ? setDataAnalisa(findData.analisa) : setDataAnalisa(data)
  }

  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTableTabs(newPage);
  };

  const grandTotal = () => {
    const getSubtotal = (data) => data?.reduce((acc, { harga, qty }) => {
      const subtotal = Math.round(parseFloat(qty ?? 0) * parseFloat(harga ?? 0))
      return acc + subtotal
    }, 0)

    const totalBahan = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '1'))
    const totalUpah = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '2'))
    const totalAlatMesin = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '3'))
    const totalSubkon = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '4'))
    const totalOverhead = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '5' && item.id_kelompok !== '13'))
    const totalBOK = getSubtotal(dataAnalisa?.filter(item => item.id_buaso === '5' && item.id_kelompok === '13'))

    const grandTotal = totalBahan + totalUpah + totalAlatMesin + totalSubkon + totalOverhead + totalBOK

    return {
      totalBahan,
      totalUpah,
      totalAlatMesin,
      totalSubkon,
      totalOverhead,
      totalBOK,
      grandTotal
    }
  }

  const submitHandler = () => {
    setIsSubmitting(true)

    const finalValues = {
      unique: indexAnalisa,
      analisa: dataAnalisa
    }

    // Update Harga Satuan Barang Jadi Yang Diubah
    const newDataBarangJadi = dataBarangJadi?.map(item => item.unique === indexAnalisa ?
      {
        ...item,
        harga_satuan: grandTotal().grandTotal,
      }
      : item)

    // Cek jika data analisa barang jadi sudah ada di context
    const isNewAnalisaBarangJadi = Boolean(dataAnalisaBarangJadi?.find(item => item.unique === indexAnalisa))
    const newDataAnalisaBarangJadi = isNewAnalisaBarangJadi ? dataAnalisaBarangJadi?.map(item => item.unique === indexAnalisa ? { ...item, analisa: finalValues.analisa } : item) : [...dataAnalisaBarangJadi, finalValues]

    setDataAnalisaBarangJadi(newDataAnalisaBarangJadi)
    setDataBarangJadi(newDataBarangJadi)
    setModalConfig({ ...modalConfig, show: false })
  }

  const tabsConfig = [
    {
      tab: "bahan",
      label: "Bahan",
      component: () => (
        <TableAnalisa
          id_buaso={'1'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectBahan}
          tableHead={{ column3: 'Item Bahan', column5: 'Harga Satuan' }}
        />
      ),
    },
    {
      tab: 'upah',
      label: 'Upah',
      component: () => (
        <TableAnalisa
          id_buaso={'2'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectUpah}
          tableHead={{ column3: 'Item Upah', column5: 'Harga Satuan' }}
        />
      )
    },
    {
      tab: 'alatmesin',
      label: 'Alat & Mesin',
      component: () => (
        <TableAnalisa
          id_buaso={'3'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectAlatMesin}
          tableHead={{ column3: 'Item Alat & Mesin', column5: 'Harga Satuan' }}
        />
      )
    },
    {
      tab: 'subcont',
      label: 'Subcont',
      component: () => (
        <TableAnalisa
          id_buaso={'4'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectSubkon}
          tableHead={{ column3: 'Item Subcont', column5: 'Harga Satuan' }}
        />
      )
    },
    {
      tab: 'overhead',
      label: 'Overhead',
      component: () => (
        <TableAnalisa
          tab={'OVERHEAD'}
          id_buaso={'5'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectOverhead}
          tableHead={{ column3: 'Item Overhead', column5: 'Harga Satuan' }}
        />
      )
    },
    {
      tab: 'bok',
      label: 'BOK',
      component: () => (
        <TableAnalisa
          tab={'BOK'}
          id_buaso={'5'}
          data={dataAnalisa}
          setData={setDataAnalisa}
          dataSelect={dataSelectBOK}
          tableHead={{ column3: 'Item BOK', column5: 'Harga Satuan' }}
        />
      )
    }
  ]

  useEffect(() => {
    isAnalisaExistinContext(indexAnalisa)

    return () => { };
  }, [data, indexAnalisa]);

  return (
    <>
      <h6 className='mt-4 font-weight-bold'>Data Analisa Barang Jadi</h6>
      <Card>
        <Card.Header>
          {/* Tab Header */}
          <Nav variant="tabs" defaultActiveKey={`#${tableTabs}`}>
            {tabsConfig.map((val, index) => (
              <Nav.Item key={`${index}-${val.label}`}>
                <Nav.Link href={`#${val.tab}`} onClick={(e) => onTabsChangeHandler(e, val.tab)}>
                  {val.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {tabsConfig.map(({ tab, component: Component }, index) => tab === tableTabs && <Component key={index} />)}
        </Card.Body>
      </Card>

      <h6 className='mt-4 font-weight-bold'>Summary Biaya</h6>
      <Card>
        <Card.Body>
          <TableSummary grandTotal={grandTotal} />
        </Card.Body>
      </Card>

      {/* 
      {contextType !== 'DETAIL' && <div className='mt-5 d-flex justify-content-end align-items-center'>
        <ActionButton
          variant='primary'
          text='Simpan Analisa Barang Jadi'
          loading={isSubmitting}
          onClick={() => submitHandler()} />
      </div>} */}
    </>
  )
}

export default TableSection
