// React
import React, { PureComponent } from "react";

// Components
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../../components";
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
} from "../../../../../utilities";
import { Row, Col } from "react-bootstrap";

// View Components
import { InfoItems, KopSuratSection, Total, TTDSection } from "./components";

const PrintContent = ({ dataPenawaran, dataBarangJadi }) => {
  const InfoSection = () => {
    return (
      <table>
        <tbody>
          <InfoItems title="Perihal" value="Penawaran" />
          <InfoItems
            title="No. Penawaran"
            value={dataPenawaran.no_penawaran ?? "-"}
          />
          <InfoItems
            title="Tgl. Penawaran"
            value={
              dataPenawaran.tgl_penawaran
                ? DateConvert(new Date(dataPenawaran.tgl_penawaran)).custom
                : "-"
            }
          />
          <InfoItems title="No. RAB" value={dataPenawaran.no_rab ?? "-"} />
          <InfoItems
            title="Tgl. RAB"
            value={
              dataPenawaran.tgl_rab
                ? DateConvert(new Date(dataPenawaran.tgl_rab)).custom
                : "-"
            }
          />
          <InfoItems
            title="Kepada"
            value={dataPenawaran.nama_customer ?? "-"}
          />
          <InfoItems title="ATT" value={dataPenawaran.att ?? "-"} />
          <InfoItems title="Proyek" value={dataPenawaran.nama_proyek ?? "-"} />
        </tbody>
      </table>
    );
  };

  const ListItemPurchaseOrderSection = () => {
    let total = 0;

    if (!(dataBarangJadi && dataBarangJadi?.length > 0)) {
      return <DataStatus text="Tidak ada data" />;
    } else {
      return (
        <>
          <b>List Item Penawaran</b>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item</ThFixed>
                <Th>Nama Item</Th>
                <ThFixed>Qty</ThFixed>
                <ThFixed>Satuan</ThFixed>
                <ThFixed>Harga Satuan</ThFixed>
                <ThFixed>Sub Total</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {dataBarangJadi.map((val, index) => {
                const harga = val.rounded ? parseFloat(val.rounded) : 0;
                const qty = val.qty_rab ? parseFloat(val.qty_rab) : 0;
                const sub_total = qty * harga;
                total += sub_total;
                const diskon = dataPenawaran.diskon
                  ? parseFloat(dataPenawaran.diskon)
                  : 0;
                const sebelumDiskon = (diskon / 100) * total;
                const hasilDiskon = total - sebelumDiskon;
                const ppn = dataPenawaran.ppn
                  ? parseFloat(dataPenawaran.ppn)
                  : 0;
                const sebelumPpn = (ppn / 100) * hasilDiskon;
                const hasilPpn = hasilDiskon + sebelumPpn;

                return (
                  <>
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_item}</TdFixed>
                      <Td>{val.nama_item}</Td>
                      <Td textRight>{qty}</Td>
                      <Td>{val.satuan ?? "-"}</Td>
                      <Td textRight>
                        {RupiahConvert(harga.toString()).getWithComa}
                      </Td>
                      <Td textRight>
                        {RupiahConvert(sub_total.toString()).getWithComa}
                      </Td>
                    </Tr>
                    {index + 1 === dataBarangJadi.length && (
                      <>
                        <Total title="Total">
                          {RupiahConvert(total.toString()).getWithComa}
                        </Total>
                        <Total
                          title={`Diskon (${
                            DecimalConvert(diskon).getWithComa
                          }%)`}>
                          {
                            RupiahConvert(parseFloat(sebelumDiskon).toString())
                              .getWithComa
                          }
                        </Total>
                        <Total
                          title={`PPN (${DecimalConvert(ppn).getWithComa}%)`}>
                          {
                            RupiahConvert(parseFloat(sebelumPpn).toString())
                              .getWithComa
                          }
                        </Total>
                        <Total title="Total Setelah PPN">
                          {
                            RupiahConvert(parseFloat(hasilPpn).toString())
                              .getWithComa
                          }
                        </Total>
                      </>
                    )}
                  </>
                );
              })}
            </TBody>
          </Table>
        </>
      );
    }
  };

  return (
    <div className="p-4">
      <KopSuratSection />
      <InfoSection />
      <hr />

      <div className="mb-5">
        <div className="py-2 pl-1">
          <td
            dangerouslySetInnerHTML={{ __html: dataPenawaran.teks_pembuka }}
          />
        </div>
        <ListItemPurchaseOrderSection />
        <div>
          <td
            dangerouslySetInnerHTML={{ __html: dataPenawaran.teks_penutup }}
          />
        </div>
      </div>

      <Row>
        <Col>
          <TTDSection
            title="Hormat Kami"
            value="I Dewa Gede Tagel"
            jabatan="Direktur"
          />
        </Col>
        <Col />
      </Row>
    </div>
  );
};

export default class PrintPenawaran extends PureComponent {
  render() {
    return (
      <PrintContent
        dataPenawaran={this?.props?.dataPenawaran ?? {}}
        dataBarangJadi={this?.props?.dataBarangJadi ?? []}
      />
    );
  }
}
