import Services from "../../../services";

class InfoPeluangApi {
  getPage(params) {
    return Services.get("/info_peluang/page", { params });
  }

  getNoBaru(data) {
    return Services.get(`/info_peluang/no_baru?tanggal=${data}`);
  }

  getDropdownCustomer() {
    return Services.get("/peluang/customer");
  }

  getDropdownProyek() {
    return Services.get("/dropdown/proyek");
  }

  getDropdownKaryawan() {
    return Services.get("/karyawan");
  }

  create(data) {
    return Services.post("/info_peluang", data);
  }

  updateStatus(data) {
    return Services.put("info_peluang/status_peluang", data);
  }

  getSingle(data) {
    return Services.get(`/info_peluang/single?id_info_peluang=${data}`);
  }

  update(data) {
    return Services.put("/info_peluang", data);
  }

  hide(data) {
    return Services.put("/peluang/hide", data);
  }

  show(data) {
    return Services.put("/peluang/show", data);
  }

  delete(data) {
    return Services.post("/peluang/delete", data);
  }

  deleteDetail(data) {
    return Services.post("/peluang/detail_delete", data);
  }
}

export default new InfoPeluangApi();
